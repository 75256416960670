import React, { useState, useEffect, useRef } from "react"
import { Table, Form, Button, Spinner, Row, Col } from "react-bootstrap"
import BarLoader from "react-spinners/ClipLoader"
import { useSelector, useDispatch } from "react-redux"
import CheckIcon from "@mui/icons-material/Check"
import CloseIcon from "@mui/icons-material/Close"
import { WysiwygEditor } from "../../../../Reuseable/WysiwygEditor"
import { Activity } from "../Activity"
import TrackingModalView from "../style"
import { api } from "../../../../../Services/api"
import { setAllTrackings, setMyTrackings } from "../../../../../redux/Tracking/actionCreator"
import EditIcon from "../../../../../../src/assets/images/icons/noun-edit-3556018.svg"
import CopyIcon from "../../../../../../src/assets/images/icons/copy.svg"
import usePrevious from "../../../../../Hooks/usePrevious"
import FormWrap from "../../../../Reuseable/FileUploader/styles"
import IconButton from "@mui/material/IconButton"
import DownloadIcon from "@mui/icons-material/Download"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import UploadFileIcon from "@mui/icons-material/UploadFile"
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft"
import Iframe from "react-iframe"
import Alert from "@mui/material/Alert"
import FileCopyIcon from "@mui/icons-material/FileCopy"
import TrackingViewWrap from "./style"
import ModalImage from "react-modal-image"
import DeleteIcon from "@mui/icons-material/Delete"
import ContentCopyIcon from "@mui/icons-material/ContentCopy"
import useDownloader from "react-use-downloader"
import UIModal from "../../../../Reuseable/Modal"
import { useFetch } from "../../../../../Hooks/useFetch"
import { key } from "../../../../../data/queryKeys"
import moment from "moment"
import { client } from "../../../../.."
import { fileToBase64, toBase64 } from "../../../../../Utilities/commonFunctions"

export const TrackingView = ({
  isModalView,
  setTaskview,
  item,
  setTrackingItemData,
  fileLoading,
  setTaskFileType,
  setTaskFileList,
  taskfileType,
  taskfileList,
  taskfile,
  setTaskFile,
  data,
  setViewTaskData,
  showEditTask,
  setShowEditTask,
  setShowClone,
  updatedData,
  setUpdatedData,
  history,
  setHistory,
  type,
  name,
  value,
  setDeleteConfirmationShow,
  selected_menu,
  deletePermission,
  editPermission,
  refreshfiles,
  backlogsPermission,
  menu
}) => {
  // console.log("item-->", item)
  const authToken = useSelector((state) => state.Auth.authToken)
  const dispatch = useDispatch()
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  const allTrackingsList = useSelector((state) => state.Tracking.allTrackings)
  const myTrackingList = useSelector((state) => state.Tracking.myTrackings)
  const index = allTrackingsList.findIndex((element) => element.trackingid === item.trackingid)
  const myTaskIndex = myTrackingList.findIndex((element) => element.trackingid === item.trackingid)
  const [loading, setLoading] = useState(false)
  const [msg, setMsg] = useState()
  const [fileloading, setfileLoading] = useState(false)
  const [FileLoading, setFileLoading] = useState(false)
  const [showAddBtn, setShowAddBtn] = useState(false)
  const [showSaveBtn, setShowSaveBtn] = useState(false)
  const [selectedFile, setSelectedFile] = useState("")
  const [FileUrl, setlFileUrl] = useState([])
  const [deletedFile, setDeletedFile] = useState([])
  const [dragActive, setDragActive] = useState(false)
  const [fileType, setFileType] = useState([])
  const [fname, setFName] = useState([])
  const [fileUploadMessage, setFileUploadMessage] = useState(false)
  const inputRef = useRef(null)
  const [textCopyMsg, setTextCopyMsg] = useState()
  const [fileSize, setFileSize] = useState("")
  const [taskfilesize, setTaskFilesize] = useState("")
  const [currentFilename, setCurrentFilename] = useState("")
  const [downloading, setDownloading] = useState(false)
  const { percentage, download, isInProgress } = useDownloader()
  const [selectedRadioValue, setSelectedRadioValue] = useState("No")
  const [fileBlob, setFileBlob] = useState([])

  const titleRef = useRef()
  const descriptionRef = useRef()
  const isFinished = useRef(false)

  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    false
  )
  let allTaskTypes = allTasksTypesRes?.data ?? []

  const { data: allBacklogsRes } = useFetch(
    key.get_backlogs_data,
    `/backloglist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    true
  )

  let allBacklogs = allBacklogsRes?.data ?? []

  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allProjectsData = allProjectsRes?.data?.Items ?? []

  const { data: allMembersRes } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )

  let allMembers = allMembersRes?.data ?? []

  const closeFileupload = () => {
    setShowAddBtn(false)
    setTimeout(() => {
      setFileUploadMessage("")
    }, 3000)
  }
  useEffect(() => {
    if (isInProgress == false) {
      setDownloading(false)
    }
  }, [isInProgress])

  useEffect(() => {
    setTaskFilesize(item.filesize)
  }, [item.filesize])

  useEffect(() => {
    if (isFinished.current === false) {
      getFetchFileData(item)
      isFinished.current = true
    }

    return () => {
      setShowEditTask("")
      resetImage()
      setHistory("")
    }
  }, [])

  const getFetchFileData = (item) => {
    // console.log("getFetchFileData  item-->", item)
    for (let i = 0; i < item.fileurls.length; i++) {
      setFileLoading(true)
      api
        .get(`/tracking/fileupload/${trackingClientId}/${item.trackingid}/${item?.fileurls[i]}`)
        .then((response) => {
          if (response) {
            let Filename = item.fileurls[i]
            setTaskFile((prevfiles) => [...prevfiles, response.data.url])
            setTaskFileType((prevfiles) => [...prevfiles, Filename.split(".").pop()])
            setTaskFileList((prevfiles) => [...prevfiles, Filename])
            setFileLoading(false)
          }
        })
        .catch((error) => console.error("error-->", error))
      }
  }

  const getUploadFileData = (item) => {
      setFileLoading(true)
      api
        .get(`/tracking/fileupload/${trackingClientId}/${item.trackingid}/${item?.fileurls}`)
        .then((response) => {
          if (response) {
            let Filename = item.fileurls
            setTaskFile((prevfiles) => [...prevfiles, response.data.url])
            setTaskFileType((prevfiles) => [...prevfiles, Filename.split(".").pop()])
            setTaskFileList((prevfiles) => [...prevfiles, Filename])
            setFileLoading(false)
          }
        })
        .catch((error) => console.error("error-->", error))
  }

  const onClickSave = () => setShowSaveBtn(true)
  const ConfirmDeleteFile = () => setShowSaveBtn(false)
  const previousData = usePrevious(data)
  const priorityOptions = [
    { label: "Blocker Level 1", id: "blocker" },
    { label: "Blocker Level 2", id: "blocker2" },
    { label: "Major", id: "major" },
    { label: "Medium", id: "medium" },
    { label: "Minor", id: "minor" }
  ]
  let taskName = allTaskTypes.find((e) => e.id == data.taskType)
  if (taskName) taskName = taskName.label

  let projectName = allProjectsData.find((e) => {
    return e.trackingprojectid === data.projectid || e.number == data.projectid
  })
  if (projectName) projectName = projectName.label

  let newArr = []
  item?.workflow?.forEach((e, i) => {
    Object.keys(e).map((n) => {
      newArr.push(e[n])
    })
  })
  let result = []

  if (newArr.length > 0) {
    newArr.forEach((item, i) => {
      result.push({ label: item, id: i })
    })
    data.status_list = result
  } else {
    data.status_list = { label: "In approval", id: 0 }
  }

  let reportedByName = allMembers.find((e) => e.userId === item.reportedby)

  if (reportedByName) {
    reportedByName = reportedByName.personname
  }

  let workflowName = result.find((e) => {
    return e.id === parseInt(data.status, 10)
  })
  if (workflowName) workflowName = workflowName.label

  let results = []
  let team_mates = allProjectsData.find(
    (e) => e.trackingprojectid === data.projectid || e.number === data.projectid
  )

  let assignedToName = allMembers.find((e) => e.userId === data.assignedTo)

  if (assignedToName) assignedToName = assignedToName.personname

  if (team_mates) {
    team_mates = team_mates.teammates
  }
  if (team_mates) {
    team_mates.forEach((element) => {
      results.push({ label: element.label, id: element.id })
    })
    allMembers = [...results, { label: "None", id: "notAssigned" }]
  } else {
    allMembers = [{ label: "None", id: "notAssigned" }]
  }

  const editItem = (item) => {
    if (item === "title")
      setShowEditTask((prevState) => {
        return { ...prevState, editTitle: true }
      })
    if (item === "project")
      setShowEditTask((prevState) => {
        return { ...prevState, editProject: true }
      })
    if (item === "task_type")
      setShowEditTask((prevState) => {
        return { ...prevState, editTaskType: true }
      })
    if (item === "assigned_to")
      setShowEditTask((prevState) => {
        return { ...prevState, editAssignedTo: true }
      })
    if (item === "priority")
      setShowEditTask((prevState) => {
        return { ...prevState, editPriority: true }
      })
    if (item === "timeline")
      setShowEditTask((prevState) => {
        return { ...prevState, editTimeline: true }
      })
    if (item === "status")
      setShowEditTask((prevState) => {
        return { ...prevState, editStatus: true }
      })
    if (item === "description")
      setShowEditTask((prevState) => {
        return { ...prevState, editDescription: true }
      })
    if (item === "fileurls")
      setShowEditTask((prevState) => {
        return { ...prevState, editfileurls: true }
      })
  }

  useEffect(() => {
    if (showEditTask.editTitle) {
      titleRef.current.focus()
    }
    if (showEditTask.editDescription) {
      descriptionRef.current.focusEditor()
    }
  }, [showEditTask])

  const updateTask = (name, value) => {
    setLoading(true)
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone
    let Obj = {
      name: name,
      value: value,
      timezone: timezone
    }
    if (name === "title")
      setViewTaskData((prevState) => {
        return { ...prevState, title: value }
      })
    if (name === "timeline")
      setViewTaskData((prevState) => {
        return { ...prevState, timeline: value }
      })
    if (value) {
      api
        .patch("/tracking/update/" + item.trackingid, Obj, {
          headers: {
            authorizationtoken: `${authToken}`
          }
        })
        .then(function (response) {
          setLoading(false)
          if (response.status === 200) {
            deletedFile.length > 0 && DeleteFile()
            setHistory(response.data.updatedHistory)
            if (index !== -1 || index === 0) {
              allTrackingsList[index][name] = value
              allTrackingsList[index]["history"] = response.data.updatedHistory
              var newObj = [...allTrackingsList]
              dispatch(setAllTrackings(newObj))
            }
            if (myTaskIndex !== -1 || myTaskIndex === 0) {
              myTrackingList[myTaskIndex][name] = value
              myTrackingList[myTaskIndex]["history"] = response.data.updatedHistory
              const newObj = [...myTrackingList]
              dispatch(setMyTrackings(newObj))
            }
            if (name === "description") {
              setViewTaskData((prevState) => {
                return { ...prevState, description: value }
              })
              setShowEditTask((prevState) => {
                return { ...prevState, editDescription: false }
              })
            }
          }
        })
        .catch((_error) => {})
    }
  }

  const editIconCSS = {
    marginLeft: "3%",
    cursor: "pointer",
    marginTop: "1%"
  }

  const copyIconCSS = {
    marginLeft: "3%",
    cursor: "pointer",
    marginTop: "2%"
  }

  const tickCloseIconCSS = {
    fontSize: "17px",
    marginTop: "-2px",
    marginLeft: "1%"
  }

  const copyTicketNumber = (e) => {
    var text = trackingClientId + "-" + item.trackingid
    navigator.clipboard.writeText(text)
    setMsg("Copied")
    setTimeout(() => {
      setMsg("")
    }, 750)
  }

  const copyText = (e) => {
    var text = data.title
    navigator.clipboard.writeText(text)
    setTextCopyMsg("Copied")
    setTimeout(() => {
      setTextCopyMsg("")
    }, 750)
  }

  const handleDrag = function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(true)
  }
  const handleDrop = function (e) {
    e.preventDefault()
    e.stopPropagation()
    setDragActive(false)
    setSelectedFile((prevfiles) => [...prevfiles, e.dataTransfer.files[0]])
    setFName((prevfiles) => [...prevfiles, e.dataTransfer.files[0].name])
    setFileType((prevfiles) => [...prevfiles, e.dataTransfer.files[0].name.split(".").pop()])
    setFileSize((prevfiles) => [...prevfiles, e.dataTransfer.files[0].size])
    setShowAddBtn(true)
  }

  const handleChange = (e) => {
    const Files = e.target.files[0]
    setShowAddBtn(true)
    if (Files) {
      setSelectedFile((prevfiles) => [...prevfiles, Files])
      setFName((prevfiles) => [...prevfiles, Files.name])
      setFileType((prevfiles) => [...prevfiles, Files.name.split(".").pop()])
      setFileSize((prevfiles) => [...prevfiles, Files.size])
    }
  }

  const onButtonClick = () => {
    inputRef.current.click()
  }

  useEffect(() => {
    if (selectedFile.length < 1) return
    const newImageURL = []
    setFileBlob(selectedFile)
    selectedFile.forEach((selectedFile) => newImageURL.push(URL.createObjectURL(selectedFile)))
    setlFileUrl(newImageURL)

    const updatedFilesize = Array.isArray(item.filesize) ? item.filesize : []
    setUpdatedData((prevState) => {
      return { ...prevState, fileurls: [...fname, ...taskfileList] }
    })
    setUpdatedData((prevState) => {
      return { ...prevState, filesize: [...fileSize, ...updatedFilesize] }
    })
  }, [selectedFile])

  var FileArrayUpload = []
  for (var i = 0; i < FileUrl.length; i++) FileArrayUpload[i] = [fileType[i], FileUrl[i], fname[i]]

  const removeImage = (index) => {
    if (FileUrl.length === 1 && index === 0) {
      inputRef.current.value = ""
      setShowAddBtn(false)
    }
    setlFileUrl([...FileUrl.slice(0, index), ...FileUrl.slice(index + 1, FileUrl.length)])
    setFileSize([...fileSize.slice(0, index), ...fileSize.slice(index + 1, fileSize.length)])
    setFName([...fname.slice(0, index), ...fname.slice(index + 1, FileUrl.length)])
    setFileType([...fileType.slice(0, index), ...fileType.slice(index + 1, fileType.length)])
    setSelectedFile([
      ...selectedFile.slice(0, index),
      ...selectedFile.slice(index + 1, selectedFile.length)
    ])
    const result = FileArrayUpload.map((element) => element.slice(index))
  }

  const handleUploadFile = async (trackingid) => {
    for (let i = 0; i < fileBlob.length; i++){
    const base64file = await fileToBase64(fileBlob[i])
    setFileUploadMessage(null)
    setfileLoading(true)
    try {
      const response = await api.post(
        "/tracking/fileupload",
        {
          trackingclientid: trackingClientId,
          trackingid: trackingid,
          fileurls: fileBlob[i].name,
          fileType: fileBlob[i].type,
          filedata: base64file
        },
        {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        }
      )
      let Filename = fileBlob[i]?.name
      setCurrentFilename(Filename)
      closeFileupload()
      setfileLoading(false)
      setFileUploadMessage(response.data.message)

      getUploadFileData({
        trackingid: trackingid,
        fileurls: fileBlob[i].name
      })

      updateTask("fileurls", [...item.fileurls, Filename])
      item.fileurls = [...item.fileurls, Filename];
      // setTaskFile((prevfiles) => [...prevfiles, response.data.url])
      // if (Filename) {
      //   setTaskFileType((prevfiles) => [...prevfiles, Filename.split(".").pop()])
      //   setTaskFileList((prevfiles) => [...prevfiles, Filename])
      // }
    } catch (error) {
      console.log(error)
    }
  }}

  const [fileArray, setFileArray] = useState([])

  useEffect(() => {
    let files = []

    if (taskfile) {
      for (let i = 0; i < taskfile.length; i++) {
        files[i] = [taskfileType[i], taskfile[i], taskfileList[i]]
      }

      setFileArray(files)
    }

    return () => {
      setFileArray([])
    }
  }, [taskfile, taskfileType, taskfileList])

  const initial = []
  const resetImage = () => {
    setTaskFile(initial)
    setShowAddBtn(false)
    setShowSaveBtn(false)
    setTaskFileType(initial)
    setTaskFileList(initial)
    setSelectedFile(initial)
    setFName(initial)
    setFileType(initial)
    setlFileUrl(initial)
    setDeletedFile(initial)
    setFileSize(initial)
  }
  const resetUpload = () => {
    setSelectedFile(initial)
    setFName(initial)
    setFileType(initial)
    setlFileUrl(initial)
    setFileSize(initial)
  }

  const deleteFile = (index) => {
    setDeletedFile((prevfiles) => [...prevfiles, taskfileList[index]])

    if (taskfileList) {
      setTaskFileList([
        ...taskfileList.slice(0, index),
        ...taskfileList.slice(index + 1, taskfileList.length)
      ])
    }

    if (fileArray) {
      const result = fileArray.map((element) => element.slice(index))
    }

    if (taskfileType) {
      setTaskFileType([
        ...taskfileType.slice(0, index),
        ...taskfileType.slice(index + 1, taskfileType.length)
      ])
    }

    if (taskfile) {
      setTaskFile([...taskfile.slice(0, index), ...taskfile.slice(index + 1, taskfile.length)])
    }

    if (taskfilesize) {
      setTaskFilesize([
        ...taskfilesize.slice(0, index),
        ...taskfilesize.slice(index + 1, taskfilesize.length)
      ])
    }
  }

  const DeleteFile = () => {
    let Obj = deletedFile

    api
      .post("/tracking/update/" + item.trackingid, Obj, {
        headers: {
          authorizationtoken: ` ${authToken}`
        }
      })
      .then(function (response) {
        if (response.status === 200) {
          if (response.data.status === true) {
            setDeletedFile([])
          }
        }
      })
      .catch(function (error) {
        setDeletedFile([])
      })
  }

  useEffect(() => {
    if (taskfileList) {
      setUpdatedData((prevState) => {
        return { ...prevState, fileurls: taskfileList }
      })
    }
  }, [taskfileList])

  const handleSplChar = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault()
      editItem("title")
      updateTask("title", updatedData.title)
      setShowEditTask((prevState) => {
        return { ...prevState, editTitle: false }
      })
    }
    if (
      e.key === "#" ||
      e.key === "$" ||
      e.key === "^" ||
      e.key === "@" ||
      e.key === "*" ||
      e.key === "(" ||
      e.key === "!" ||
      e.key === ")" ||
      e.key === "%" ||
      e.key === "="
    ) {
      e.preventDefault()
    }
  }

  const exceptThisSymbols = ["e", "E", "+", "-", "."]

  // async function writeClipImg(imageBase64) {
  //   try {
  //     const blob = await (await fetch(`data:image/png;base64,${imageBase64}`)).blob()
  //     if (navigator.clipboard && navigator.clipboard.write) {
  //       await navigator.clipboard.write([
  //         new ClipboardItem({
  //           [blob.type]: blob
  //         })
  //       ])
  //     } else {
  //       console.error("Clipboard API not available")
  //     }
  //   } catch (err) {
  //     console.error("Error while fetching or writing to clipboard:", err)
  //   }
  // }

  const downloadFile = (url, fileName) => {
    const newTab = window.open(url, "_blank")

    if (!newTab || newTab.closed || typeof newTab.closed == "undefined") {
      alert("Please disable your pop-up blocker and try again.")
    }
    // const fileUrl = url
    // fetch(fileUrl)
    //   .then((response) => response.blob())
    //   .then((blob) => {
    //     const url = window.URL.createObjectURL(blob)
    //     const link = document.createElement("a")
    //     link.href = url
    //     link.setAttribute("download", fileName)
    //     link.style.display = "none" // Hide the link
    //     document.body.appendChild(link)
    //     link.click()
    //     document.body.removeChild(link)
    //   })
    //   .catch((error) => {
    //     console.error("Error downloading the file:", error)
    //   })
  }

  const [viewBaclogsToggle, setViewBacklogsToggle] = useState("")
  const [showAddBacklogstModal, setShowAddBacklogsModal] = useState(false)
  const [moveTrackingId, setMoveTrackingId] = useState("")
  let forBacklogsModel = true

  const fetchViewBacklogssList = (element) => {
    setLoading(true)
    let url = `/backloglist/${trackingClientId}/${element}`
    api
      .post(url)
      .then(function (response) {
        if (response.data.status === true) {
          if (menu === "alltask") {
            let index = allTrackingsList.findIndex((ele) => ele.trackingid === element)
            if (index >= 0) {
              allTrackingsList.splice(index, 1)
            }
          } else {
            let index = myTrackingList.findIndex((ele) => ele.trackingid === element)
            if (index >= 0) {
              myTrackingList.splice(index, 1)
            }
          }
          allBacklogs.push(index)
          dispatch(setAllTrackings([...allTrackingsList]))
          client.setQueryData([key.get_backlogs_data], (oldData) => {
            return {
              ...oldData,
              data: allBacklogs
            }
          })
        }
        setTaskview("AllTask")
        setLoading(false)
      })
      .catch((error) => {
        setLoading(false)
      })
  }

  const onRadiobtnHandler = (e, value) => {
    setSelectedRadioValue(e.target.value)
    if (value && value.name === e.target.name) {
      setViewBacklogsToggle((p) => ({
        ...p,
        [e.target.name]: e.target.value
      }))
    }
  }

  const handleMoveBacklogsModel = (item) => {
    setMoveTrackingId(item)
    setShowAddBacklogsModal(true)
  }

  return (
    <TrackingViewWrap>
      <TrackingModalView>
        {!isModalView && (
          <ArrowCircleLeftIcon
            className='closeicon'
            onClick={() => {
              setTaskview("AllTask")
              resetImage()
            }}
            style={{ cursor: "pointer", fontSize: "25px" }}
          />
        )}

        <h4>
          <div style={{ width: "100%" }}>
            {showEditTask && showEditTask.editTitle ? (
              <>
                <Row>
                  <Col sm={8} md={9} lg={9}>
                    <input
                      ref={titleRef}
                      type='text'
                      value={updatedData.title}
                      onKeyDown={handleSplChar}
                      onChange={(e) =>
                        setUpdatedData((prevState) => {
                          return { ...prevState, title: e.target.value }
                        })
                      }
                      style={{ fontSize: 24 }}
                      maxLength='80'
                      className='form-control p-0 ps-2'
                    />
                  </Col>
                  <Col sm={4} md={3} lg={3}>
                    <div className='titledit' style={{ zIndex: 1, position: "relative" }}>
                      <Button
                        size='sm'
                        variant='secondary'
                        style={{
                          color: "#9d9d9d",
                          background: "#f1f1f1",
                          borderColor: "#d3d3d3"
                        }}
                        className='titleedit me-1 ps-3 pe-3 border-none'
                        onClick={(e) => {
                          updateTask("title", updatedData.title)
                          setShowEditTask((prevState) => {
                            return { ...prevState, editTitle: false }
                          })
                        }}
                      >
                        <CheckIcon className='titleicon' />
                      </Button>
                      <Button
                        size='sm'
                        variant='secondary'
                        style={{
                          color: "#9d9d9d",
                          background: "#f1f1f1",
                          borderColor: "#d3d3d3"
                        }}
                        className='titleedit ps-3 pe-3  border-none'
                        onClick={(e) =>
                          setShowEditTask((prevState) => {
                            return { ...prevState, editTitle: false }
                          })
                        }
                      >
                        <CloseIcon className='titleicon' />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <span style={{ width: "90%" }} className='edit-content title pt-0'>
                {data.title}
                <img
                  src={CopyIcon}
                  alt='editIcon'
                  height={17}
                  width={17}
                  style={{
                    marginTop: "0.7%",
                    marginLeft: "1%",
                    cursor: "pointer"
                  }}
                  onClick={copyText}
                />
                <span
                  style={{
                    fontSize: "15px",
                    color: "#4EB6EE",
                    marginTop: "0.7%",
                    marginLeft: "1%"
                  }}
                >
                  {textCopyMsg}
                </span>
                {editPermission && (
                  <img
                    src={EditIcon}
                    alt='editIcon'
                    height={19}
                    width={19}
                    style={{
                      marginLeft: "0.3%",
                      cursor: "pointer",
                      marginTop: "0.5%"
                    }}
                    onClick={(e) => {
                      editItem("title")
                    }}
                  />
                )}
              </span>
            )}
          </div>
        </h4>
        <div className='taskconatiner p-2'>
          <div className='m-2 my-4 clone-box'>
            {!isModalView ? (
              <div
                className='clone'
                onClick={(e) => {
                  setShowClone(true)
                }}
              >
                <FileCopyIcon /> <strong>Clone</strong>
              </div>
            ) : (
              <div></div>
            )}

            {deletePermission && (
              <IconButton color='error'>
                <DeleteIcon
                  onClick={(e) => {
                    setDeleteConfirmationShow(true)
                    setTrackingItemData(
                      (selected_menu === "all_tasks" ? allTrackingsList : myTrackingList).find(
                        (element) => element.trackingid === item.trackingid
                      )
                    )
                  }}
                />
              </IconButton>
            )}
          </div>
          <Table className='trackingviewtable'>
            <tbody className='trackingviewtbody'>
              <div className='trackingviewtbody'>
                <Row>
                  <Col sm={12} md={12} lg={6}>
                    <tr className=''>
                      <td className='border-0 tr-color select-options'>
                        <strong>Task No:</strong>
                      </td>
                      <td className=' border-0  text-capitalize'>
                        <strong className='edit-content tr-color'>
                          {trackingClientId}-{item.trackingid}
                          <img
                            src={CopyIcon}
                            height={15}
                            width={15}
                            style={copyIconCSS}
                            onClick={copyTicketNumber}
                            alt='copy_icon'
                          />
                          <span
                            style={{
                              marginLeft: "4%",
                              color: "#4EB6EE",
                              fontWeight: "300"
                            }}
                          >
                            {msg}
                          </span>
                        </strong>
                      </td>
                    </tr>
                  </Col>
                  {backlogsPermission?.view == true && backlogsPermission?.edit == true && (
                    <Col sm={12} md={12} lg={6}>
                      <tr className='tr-color'>
                        <td className='border-0 select-options datarow'>Backlogs:</td>
                        <td className='border-0 text-capitalize'>
                          <div className='backlogs_radio_btns'>
                            <div>
                              <Form.Check
                                label='Yes'
                                name='backlogs'
                                type='radio'
                                value='Yes'
                                checked={selectedRadioValue === "Yes"}
                                onChange={(e) => {
                                  handleMoveBacklogsModel(item.trackingid)
                                }}
                              />
                            </div>
                            <div>
                              <Form.Check
                                label='No'
                                name='backlogs'
                                type='radio'
                                value='No'
                                checked={selectedRadioValue === "No"}
                                onChange={(e) => {
                                  onRadiobtnHandler(e, value)
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    </Col>
                  )}
                  <Col sm={12} md={12} lg={6}>
                    <tr className='tr-color '>
                      <td className='border-0 select-options datarow'>Project:</td>
                      <td className='border-0 text-capitalize'>
                        {showEditTask && showEditTask.editProject ? (
                          <div className='d-flex'>
                            <Form.Select
                              className='select-options'
                              size='sm'
                              value={data.projectid}
                              onChange={(e) => {
                                setViewTaskData((prevState) => {
                                  return {
                                    ...prevState,
                                    projectid: e.target.value
                                  }
                                })
                              }}
                            >
                              {allProjectsData.map((item, index) => (
                                <option value={item.trackingprojectid} key={index}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Select>
                            <div
                              className='ms-2 text-center edit-cross'
                              onClick={(e) => {
                                setShowEditTask((prevState) => {
                                  return { ...prevState, editProject: false }
                                })
                                updateTask("trackingprojectsid", data.projectid)
                                updateTask("assignedto", 'notAssigned')
                                setViewTaskData((prevState) => {
                                  return {
                                    ...prevState,
                                    assignedTo: 'notAssigned'
                                  }
                                })
                              }}
                            >
                              <CheckIcon style={tickCloseIconCSS} />
                            </div>
                            <div
                              className='ms-2 text-center edit-cross'
                              onClick={(e) => {
                                setShowEditTask((prevState) => {
                                  return { ...prevState, editProject: false }
                                })
                                setViewTaskData(previousData)
                              }}
                            >
                              <CloseIcon style={tickCloseIconCSS} />
                            </div>
                          </div>
                        ) : (
                          <span className='edit-content datarow'>
                            {projectName}
                            {editPermission && (
                              <img
                                src={EditIcon}
                                alt='editIcon'
                                height={17}
                                width={17}
                                style={editIconCSS}
                                onClick={(e) => editItem("project")}
                              />
                            )}
                          </span>
                        )}
                      </td>
                    </tr>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <tr className='tr-color '>
                      <td className='border-0 select-options trackingdatarow'>Task type:</td>
                      <td className='border-0 text-capitalize'>
                        {showEditTask && showEditTask.editTaskType ? (
                          <div className='d-flex'>
                            <Form.Select
                              className='select-options'
                              size='sm'
                              value={data.taskType}
                              onChange={(e) => {
                                setViewTaskData((prevState) => {
                                  return {
                                    ...prevState,
                                    taskType: e.target.value
                                  }
                                })
                              }}
                            >
                              {allTaskTypes.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Select>
                            <div
                              className='ms-2 text-center edit-cross'
                              onClick={(e) => {
                                setShowEditTask((prevState) => {
                                  return { ...prevState, editTaskType: false }
                                })
                                updateTask("type", data.taskType)
                              }}
                            >
                              <CheckIcon style={tickCloseIconCSS} />
                            </div>
                            <div
                              className='ms-2 text-center edit-cross'
                              onClick={(e) => {
                                setShowEditTask((prevState) => {
                                  return { ...prevState, editTaskType: false }
                                })
                                setViewTaskData(previousData)
                              }}
                            >
                              <CloseIcon style={tickCloseIconCSS} />
                            </div>
                          </div>
                        ) : (
                          <span className='edit-content trackingdatarow'>
                            {taskName}
                            {editPermission && (
                              <img
                                src={EditIcon}
                                alt='editIcon'
                                height={17}
                                width={17}
                                style={editIconCSS}
                                onClick={(e) => editItem("task_type")}
                              />
                            )}
                          </span>
                        )}
                      </td>
                    </tr>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <tr className='tr-color '>
                      <td className='border-0 select-options datarow'>Reported On:</td>
                      <td className='border-0'>
                        <span className='edit-content datarow'>
                          {moment(item.reporteddate).format("MMM D, YYYY hh:mm A")}
                        </span>
                      </td>
                    </tr>
                  </Col>
                  <Col sm={12} md={12} lg={6}>
                    <tr className='tr-color '>
                      <td className='border-0 select-options trackingdatarow'>Reported by:</td>
                      <td className='border-0'>
                        <span
                          className='edit-content trackingdatarow'
                          onClick={(e) => editItem("reported_by")}
                        >
                          {reportedByName}
                        </span>
                      </td>
                    </tr>
                  </Col>
                  {type !== "approve" && (
                    <Col sm={12} md={12} lg={6}>
                      <tr className='tr-color '>
                        <td className='border-0 select-options trackingdatarow'>Timeline: </td>
                        <td className='border-0'>
                          {showEditTask && showEditTask.editTimeline ? (
                            <div className='d-flex edit-content trackingdatarow'>
                              <Form.Control
                                type='number'
                                className='p-0 text-center'
                                style={{ width: "30px" }}
                                onInput={(e) =>
                                  e.target.value.length > e.target.maxLength
                                    ? (e.target.value = e.target.value.slice(0, e.target.maxLength))
                                    : ""
                                }
                                maxlength={3}
                                value={updatedData.timeline}
                                pattern='[0-9]+'
                                onChange={(e) =>
                                  setUpdatedData((prevState) => {
                                    return {
                                      ...prevState,
                                      timeline: e.target.value
                                    }
                                  })
                                }
                                onKeyDown={(e) =>
                                  exceptThisSymbols.includes(e.key) && e.preventDefault()
                                }
                              />
                              <div
                                className='ms-2 text-center edit-cross'
                                onClick={(e) => {
                                  setShowEditTask((prevState) => {
                                    return {
                                      ...prevState,
                                      editTimeline: false
                                    }
                                  })
                                  updateTask("timeline", updatedData.timeline)
                                }}
                              >
                                <CheckIcon style={tickCloseIconCSS} />
                              </div>
                              <div
                                className='ms-2 text-center edit-cross'
                                onClick={(e) =>
                                  setShowEditTask((prevState) => {
                                    return {
                                      ...prevState,
                                      editTimeline: false
                                    }
                                  })
                                }
                              >
                                <CloseIcon style={tickCloseIconCSS} />
                              </div>
                            </div>
                          ) : (
                            <span className='edit-content'>
                              {data.timeline ? data.timeline + " hours" : "-"}
                              {editPermission && (
                                <img
                                  src={EditIcon}
                                  alt='editIcon'
                                  height={17}
                                  width={17}
                                  style={editIconCSS}
                                  onClick={(e) => editItem("timeline")}
                                />
                              )}
                            </span>
                          )}
                        </td>
                      </tr>
                    </Col>
                  )}
                  <Col sm={12} md={12} lg={6}>
                    {" "}
                    <tr className='tr-color '>
                      <td className='border-0 select-options trackingdatarow'>Assigned To:</td>
                      <td className='border-0'>
                        {showEditTask && showEditTask.editAssignedTo ? (
                          <div className='d-flex'>
                            <Form.Select
                              className='select-options'
                              size='sm'
                              value={data.assignedTo !== "" ? data.assignedTo : "notAssigned"}
                              onChange={(e) => {
                                setViewTaskData((prevState) => {
                                  return {
                                    ...prevState,
                                    assignedTo: e.target.value
                                  }
                                })
                              }}
                            >
                              <option value=''>Select...</option>
                              {allMembers.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Select>
                            <div
                              className='ms-2 text-center edit-cross'
                              onClick={(e) => {
                                setShowEditTask((prevState) => {
                                  return {
                                    ...prevState,
                                    editAssignedTo: false
                                  }
                                })
                                const assignedTo = allMembers?.find(
                                  (e) => e?.id === data?.assignedTo
                                )?.id
                                // if (assignedTo !== "notAssigned")
                                updateTask("assignedto", assignedTo)
                              }}
                            >
                              <CheckIcon style={tickCloseIconCSS} />
                            </div>
                            <div
                              className='ms-2 text-center edit-cross'
                              onClick={(e) => {
                                setShowEditTask((prevState) => {
                                  return {
                                    ...prevState,
                                    editAssignedTo: false
                                  }
                                })
                                setViewTaskData(previousData)
                              }}
                            >
                              <CloseIcon style={tickCloseIconCSS} />
                            </div>
                          </div>
                        ) : (
                          <span className='edit-content trackingdatarow'>
                            {assignedToName !== "" && assignedToName !== undefined
                              ? assignedToName
                              : "Not Assigned"}
                            {editPermission && (
                              <img
                                src={EditIcon}
                                alt='editIcon'
                                style={editIconCSS}
                                height={17}
                                width={17}
                                onClick={(e) => editItem("assigned_to")}
                              />
                            )}
                          </span>
                        )}
                      </td>
                    </tr>
                  </Col>

                  <Col md={12} lg={6} sm={12}>
                    <tr className='tr-color '>
                      <td className='border-0 select-options datarow'>Priority: </td>
                      <td className='border-0 text-capitalize'>
                        {showEditTask && showEditTask.editPriority ? (
                          <div className='d-flex'>
                            <Form.Select
                              className='select-options'
                              size='sm'
                              value={data.priority}
                              onChange={(e) => {
                                setViewTaskData((prevState) => {
                                  return {
                                    ...prevState,
                                    priority: e.target.value
                                  }
                                })
                              }}
                            >
                              {priorityOptions.map((item, index) => (
                                <option value={item.id} key={index}>
                                  {item.label}
                                </option>
                              ))}
                            </Form.Select>
                            <div
                              className='ms-2 text-center edit-cross'
                              onClick={(e) => {
                                setShowEditTask((prevState) => {
                                  return { ...prevState, editPriority: false }
                                })
                                updateTask("priority", data.priority)
                              }}
                            >
                              <CheckIcon style={tickCloseIconCSS} />
                            </div>
                            <div
                              className='ms-2 text-center edit-cross'
                              onClick={(e) => {
                                setShowEditTask((prevState) => {
                                  return { ...prevState, editPriority: false }
                                })
                                setViewTaskData(previousData)
                              }}
                            >
                              <CloseIcon style={tickCloseIconCSS} />
                            </div>
                          </div>
                        ) : (
                          <span className='edit-content datarow'>
                            {data.priority === "blocker"
                              ? "Blocker Level 1"
                              : data.priority === "blocker2"
                              ? "Blocker Level 2"
                              : data.priority}{" "}
                            {editPermission && (
                              <img
                                src={EditIcon}
                                alt='editIcon'
                                height={17}
                                width={17}
                                style={editIconCSS}
                                onClick={(e) => editItem("priority")}
                              />
                            )}
                          </span>
                        )}
                      </td>
                    </tr>
                  </Col>
                  {type != "approve" && (
                    <Col md={12} lg={6} sm={12}>
                      <tr className='tr-color '>
                        <td className='border-0 select-options trackingdatarow'>Status: </td>
                        <td className='border-0'>
                          {showEditTask && showEditTask.editStatus ? (
                            <div className='d-flex'>
                              <Form.Select
                                className='select-options'
                                size='sm'
                                value={data?.status}
                                onChange={(e) => {
                                  setViewTaskData((prevState) => {
                                    return {
                                      ...prevState,
                                      status: e.target.value
                                    }
                                  })
                                }}
                              >
                                {data.status_list.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.label}
                                  </option>
                                ))}
                              </Form.Select>
                              <div
                                className='ms-2 text-center edit-cross'
                                onClick={(e) => {
                                  setShowEditTask((prevState) => {
                                    return { ...prevState, editStatus: false }
                                  })
                                  updateTask("trackingprojectstatusid", data.status)
                                }}
                              >
                                <CheckIcon style={tickCloseIconCSS} />
                              </div>
                              <div
                                className='ms-2 text-center edit-cross'
                                onClick={(e) => {
                                  setShowEditTask((prevState) => {
                                    return { ...prevState, editStatus: false }
                                  })
                                  setViewTaskData(previousData)
                                }}
                              >
                                <CloseIcon style={tickCloseIconCSS} />
                              </div>
                            </div>
                          ) : (
                            <span className='edit-content trackingdatarow'>
                              {workflowName}
                              {editPermission && (
                                <img
                                  src={EditIcon}
                                  alt='editIcon'
                                  height={17}
                                  width={17}
                                  style={editIconCSS}
                                  onClick={(e) => editItem("status")}
                                />
                              )}
                            </span>
                          )}
                        </td>
                      </tr>
                    </Col>
                  )}
                </Row>
              </div>{" "}
            </tbody>
          </Table>
          <div className='p-2'>
            <p className='mb-1'>
              <strong className='tr-color'> Description: </strong>
            </p>
            {showEditTask && editPermission && showEditTask.editDescription ? (
              <div className='mb-4 editor-section'>
                <WysiwygEditor
                  editorRef={descriptionRef}
                  handleReturn={(e, des) => {
                    if (e.keyCode === 13) {
                      setShowEditTask((prevState) => {
                        return { ...prevState, editDescription: false }
                      })
                      updateTask("description", des)
                    }
                  }}
                  value={updatedData.description}
                  onChange={(val) =>
                    setUpdatedData((prevState) => {
                      return { ...prevState, description: val }
                    })
                  }
                />
                <div className='text-end'>
                  <Button
                    size='sm'
                    sx={{ display: "flex", alignItems: "center" }}
                    variant='secondary'
                    className='titleupdate me-1 ps-3 pe-3 border-none'
                    onClick={(e) => {
                      updateTask("description", updatedData.description)
                    }}
                  >
                    {loading ? (
                      <BarLoader
                        color='#808080'
                        className='bar-loader'
                        size={14}
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      <></>
                    )}
                    <CheckIcon />
                  </Button>
                  <Button
                    size='sm'
                    variant='secondary'
                    className='titlecancel ps-3 pe-3  border-none'
                    onClick={(e) =>
                      setShowEditTask((prevState) => {
                        return { ...prevState, editDescription: false }
                      })
                    }
                  >
                    <CloseIcon />
                  </Button>
                </div>
              </div>
            ) : (
              <Row
                style={{ width: "100%" }}
                onClick={(e) => editPermission && editItem("description")}
                className='edit-content description-edit'
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            )}
          </div>

          <div className='p-2'>
            <Row>
              <Col>
                {" "}
                <p className='mb-1'>
                  <strong className='tr-color'> Attached Files: </strong>
                </p>
              </Col>
            </Row>
            {fileUploadMessage ? (
              <Alert dismissible severity='success'>
                {" "}
                {fileUploadMessage}{" "}
              </Alert>
            ) : (
              <></>
            )}

            {fileLoading ? (
              <div className='text-center mt-5 mb-5'>
                <Spinner animation='border' variant='info' />
              </div>
            ) : (
              <>
                <div className='Filecontainer'>
                  {fileArray ? (
                    <span>
                      {fileArray.map((FileArray, index) => {
                        return (
                          <>
                            <div className='file mx-2 my-4'>
                              {FileArray[0] == "mp4" ? (
                                <>
                                  <div></div>
                                  <div>
                                    <video
                                      width='200'
                                      height='120'
                                      className='video'
                                      src={FileArray[1]}
                                      controls
                                    />
                                    <p className='filename videoname'>{FileArray[2]}</p>
                                    <IconButton
                                      style={{ width: "100%" }}
                                      onClick={() => {
                                        setDownloading(true)
                                        // download(`${FileArray[1]}`, FileArray[2])
                                        downloadFile(FileArray[1], FileArray[2])
                                      }}
                                    >
                                      {downloading ? (
                                        <progress
                                          id='video'
                                          className='video_inprogress'
                                          value={percentage}
                                          max='100'
                                        />
                                      ) : (
                                        <DownloadIcon className='downloadbtn videodwn' />
                                      )}
                                    </IconButton>
                                  </div>
                                </>
                              ) : FileArray[0] == "webm" ? (
                                <>
                                  <div></div>
                                  <div>
                                    <video
                                      width='200'
                                      height='120'
                                      className='video'
                                      src={FileArray[1]}
                                      controls
                                    />
                                    <p className='filename videoname'>{FileArray[2]}</p>
                                    <IconButton
                                      style={{ width: "100%" }}
                                      onClick={() => {
                                        // download(`${FileArray[1]}`, FileArray[2])
                                        downloadFile(FileArray[1], FileArray[2])
                                      }}
                                    >
                                      <DownloadIcon className='downloadbtn videodwn' />
                                    </IconButton>
                                  </div>
                                </>
                              ) : FileArray[0] === "pdf" || FileArray[0] === "PDF" ? (
                                <>
                                  <div></div>
                                  <div>
                                    <img
                                      src='https://img.icons8.com/color/96/000000/pdf-2--v1.png'
                                      width='120px'
                                      height='120px'
                                      alt='pdf'
                                    />
                                    <p className='filename'>{FileArray[2]}</p>
                                    <IconButton
                                      style={{ width: "100%" }}
                                      onClick={() => {
                                        // download(`${FileArray[1]}`, FileArray[2])
                                        downloadFile(FileArray[1], FileArray[2])
                                      }}
                                    >
                                      <DownloadIcon className='downloadbtn' />
                                    </IconButton>
                                  </div>
                                </>
                              ) : FileArray[0] === "docx" ||
                                FileArray[0] === "doc" ||
                                FileArray[0] === "DOCX" ||
                                FileArray[0] === "DOC" ? (
                                <>
                                  <div></div>
                                  <div>
                                    <img
                                      src='https://img.icons8.com/color/144/000000/google-docs--v1.png'
                                      width='120px'
                                      height='120px'
                                      alt='doc'
                                    />
                                    <p className='filename'>{FileArray[2]}</p>
                                    <IconButton
                                      style={{ width: "100%" }}
                                      onClick={() => {
                                        // download(`${FileArray[1]}`, FileArray[2])
                                        downloadFile(FileArray[1], FileArray[2])
                                      }}
                                    >
                                      <DownloadIcon className='downloadbtn downloadicon' />
                                    </IconButton>
                                  </div>
                                </>
                              ) : FileArray[0] === "csv" ||
                                FileArray[0] === "CSV" ||
                                FileArray[0] === "xls" ||
                                FileArray[0] === "xlsx" ||
                                FileArray[0] === "XLS" ||
                                FileArray[0] === "XLSX" ? (
                                <>
                                  <div></div>
                                  <div>
                                    <img
                                      src='https://img.icons8.com/color/144/000000/microsoft-excel-2019--v1.png'
                                      alt='excel'
                                      width='120px'
                                      height='120px'
                                    />
                                    <p className='filename'>{FileArray[2]}</p>
                                    <IconButton
                                      style={{ width: "100%" }}
                                      onClick={() => {
                                        // download(`${FileArray[1]}`, FileArray[2])
                                        downloadFile(FileArray[1], FileArray[2])
                                      }}
                                    >
                                      <DownloadIcon className='downloadbtn downloadicon' />
                                    </IconButton>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div></div>
                                  <div>
                                    <div className='image-view-document'>
                                      <ModalImage
                                        small={FileArray[1]}
                                        large={FileArray[1]}
                                        hideZoom={false}
                                        showRotate={true}
                                      />
                                    </div>
                                    <p className='filename'>{FileArray[2]}</p>
                                    <div className='d-flex justify-content-center align-items-center'>
                                      <IconButton
                                        onClick={() => {
                                          downloadFile(FileArray[1], FileArray[2])
                                        }}
                                      >
                                        <DownloadIcon className='downloadbtn' />
                                      </IconButton>
                                      {/* 
                                      <IconButton
                                        onClick={() => {
                                          writeClipImg(FileArray[1])
                                        }}
                                      >
                                        <ContentCopyIcon
                                          sx={{ cursor: "copy" }}
                                          className='downloadbtn videodwn'
                                        />
                                      </IconButton> */}
                                    </div>
                                  </div>
                                </>
                              )}
                              <IconButton
                                onClick={() => {
                                  deleteFile(index)
                                  onClickSave()
                                }}
                                className='deletebtn'
                              >
                                <HighlightOffIcon />
                              </IconButton>
                            </div>
                          </>
                        )
                      })}
                    </span>
                  ) : null}
                </div>
              </>
            )}
            {showSaveBtn ? (
              <Button
                className='savechangesbtn btn-orange '
                onClick={() => {
                  ConfirmDeleteFile()
                  updateTask("fileurls", updatedData.fileurls)
                  updateTask("filesize", updatedData.taskfilesize)
                }}
              >
                Save Changes
              </Button>
            ) : null}
            <div className='pt-5'>
              <FormWrap>
                {editPermission && (
                  <div className=' '>
                    <form
                      id='form-file-upload'
                      onDragEnter={handleDrag}
                      onDragLeave={handleDrag}
                      onDragOver={handleDrag}
                      onDrop={handleDrop}
                    >
                      <input
                        ref={inputRef}
                        type='file'
                        id='input-file-upload'
                        name={name}
                        value={value}
                        onChange={(e) => {
                          handleChange(e)
                        }}
                        multiple
                        accept='image/*,video/*,.pdf,.mp4,.webm'
                      />
                      <label id='label-file-upload' className={dragActive ? "drag-active" : ""}>
                        <div>
                          <IconButton className='upload-button' onClick={onButtonClick}>
                            <UploadFileIcon fontSize='large' />
                          </IconButton>
                          <p className='upload-text'>Drag and drop file here</p>
                          <p className='upload-format'>
                            Supported formats (PNG, JPEG, JPG, Mp4, webm, Pdf)
                          </p>
                        </div>
                      </label>
                      {dragActive && (
                        <div
                          id='drag-file-element'
                          onDragEnter={handleDrag}
                          onDragLeave={handleDrag}
                          onDragOver={handleDrag}
                          onDrop={handleDrop}
                        ></div>
                      )}
                    </form>
                  </div>
                )}
                <div>
                  {selectedFile ? (
                    <span>
                      {FileArrayUpload.slice(0, 10).map((FileArrayUpload, index) => {
                        return (
                          <div className='imgRow mb-5 mt-5'>
                            {FileArrayUpload[0] == "mp4" ? (
                              <>
                                <div></div>
                                <div>
                                  <video
                                    url={FileArrayUpload[1]}
                                    height='112px'
                                    width='200px'
                                    allowFullScreen
                                  />
                                  <p className='fileuploadname'>{FileArrayUpload[2]}</p>
                                </div>{" "}
                              </>
                            ) : FileArrayUpload[0] == "webm" ? (
                              <>
                                <div></div>
                                <div>
                                  <Iframe
                                    url={FileArrayUpload[1]}
                                    height='112px'
                                    width='200px'
                                    allowFullScreen
                                  />
                                  <p className='fileuploadname'>{FileArrayUpload[2]}</p>
                                </div>{" "}
                              </>
                            ) : FileArrayUpload[0] == "pdf" || FileArrayUpload[0] == "PDF" ? (
                              <>
                                <div></div>
                                <div>
                                  <img
                                    src='https://img.icons8.com/color/96/000000/pdf-2--v1.png'
                                    width='113px'
                                    height='110px'
                                    alt='pdf'
                                  />
                                  <p className='fileuploadname'>{FileArrayUpload[2]}</p>
                                </div>
                              </>
                            ) : FileArrayUpload[0] == "docx" ||
                              FileArrayUpload[0] == "doc" ||
                              FileArrayUpload[0] == "DOCX" ||
                              FileArrayUpload[0] == "DOC" ? (
                              <>
                                <div></div>
                                <div>
                                  <img
                                    src='https://img.icons8.com/color/144/000000/google-docs--v1.png'
                                    width='113px'
                                    height='110px'
                                    alt='Document'
                                  />
                                  <p className='fileuploadname'>{FileArrayUpload[2]}</p>
                                </div>{" "}
                              </>
                            ) : FileArrayUpload[0] == "csv" ||
                              FileArrayUpload[0] == "CSV" ||
                              FileArrayUpload[0] == "xls" ||
                              FileArrayUpload[0] == "xlsx" ||
                              FileArrayUpload[0] == "XLS" ||
                              FileArrayUpload[0] == "XLSX" ? (
                              <>
                                <div></div>
                                <div>
                                  <img
                                    src='https://img.icons8.com/color/144/000000/microsoft-excel-2019--v1.png'
                                    width='113px'
                                    height='110px'
                                    alt='Csv file'
                                  />
                                  <p className='fileuploadname'>{FileArrayUpload[2]}</p>
                                </div>
                              </>
                            ) : (
                              <>
                                <div></div>
                                <div>
                                  <img src={FileArrayUpload[1]} width='113px' height='110px' />
                                  <p className='fileuploadname'>{FileArrayUpload[2]}</p>
                                </div>
                              </>
                            )}

                            <IconButton className='removebtn ' onClick={() => removeImage(index)}>
                              <HighlightOffIcon fontSize='small' />
                            </IconButton>
                          </div>
                        )
                      })}
                    </span>
                  ) : null}
                </div>
              </FormWrap>
              {showAddBtn ? (
                <>
                  <Button
                    className='btn btn-orange mt-5 float-end'
                    onClick={(e) => {
                      handleUploadFile(item.trackingid)
                      setViewTaskData((prevState) => {
                        return { ...prevState, fileurls: fname }
                      })
                      setShowEditTask((prevState) => {
                        return { ...prevState, editfileurls: false }
                      })
                      resetUpload()
                    }}
                    style={{
                      background: fileloading ? "#FFFFFF" : "#4EB6EE",
                      color: fileloading ? "#808080" : "#FFFFFF",
                      border: fileloading ? "2px solid #FFFFFF" : "2px solid #4EB6EE"
                    }}
                  >
                    {fileloading ? (
                      <BarLoader
                        color='#808080'
                        className='bar-loader'
                        size={14}
                        role='status'
                        aria-hidden='true'
                      />
                    ) : (
                      <></>
                    )}
                    Upload{""}
                  </Button>
                </>
              ) : null}
            </div>
          </div>
          {/* <br></br>
          <div className='kpy_points_container'>
            <p className='kpy_points_header'>KPI Points:</p>
            <div className='kpy_points_points_name'>
              <div className='kpy_points_status'>
                <div className='kpy_points_status_title'>
                  <p>1. Code review</p>
                </div>
                <Form.Check
                  aria-label="radio 1"
                  label="Passed"
                  name="status"
                  type="radio"
                  className='kpi_points_radio'
                />
                <Form.Check
                  aria-label="radio 2"
                  label="Failed"
                  name="status"
                  type="radio"
                  className='kpi_points_radio'
                />
                <Form.Check
                  aria-label="radio 3"
                  label="Partial"
                  name="status"
                  type="radio"
                  className='kpi_points_radio'
                />
              </div>
              <div className='kpy_points_status'>
                <div className='kpy_points_status_title'>
                  <p>2. Communication</p>
                </div>
                <Form.Check
                  aria-label="radio 1"
                  label="Passed"
                  name="Communication"
                  type="radio"
                  className='kpi_points_radio'
                />
                <Form.Check
                  aria-label="radio 2"
                  label="Failed"
                  name="Communication"
                  type="radio"
                  className='kpi_points_radio'
                />
                <Form.Check
                  aria-label="radio 3"
                  label="Partial"
                  name="Communication"
                  type="radio"
                  className='kpi_points_radio'
                />
              </div>
              <div className='kpy_points_status'>
                <div className='kpy_points_status_title'>
                  <p>3. Deadline</p>
                </div>
                <Form.Check
                  aria-label="radio 1"
                  label="Passed"
                  name="Deadline"
                  type="radio"
                  className='kpi_points_radio'
                />
                <Form.Check
                  aria-label="radio 2"
                  label="Failed"
                  name="Deadline"
                  type="radio"
                  className='kpi_points_radio'
                />
                <Form.Check
                  aria-label="radio 3"
                  label="Partial"
                  name="Deadline"
                  type="radio"
                  className='kpi_points_radio'
                />
              </div>
            </div>
          </div> */}
          <Activity item={item} history={history ? history : item.history} />
        </div>
      </TrackingModalView>
      <UIModal
        showModal={showAddBacklogstModal}
        setShowModal={setShowAddBacklogsModal}
        size='md'
        forBacklogsModel={forBacklogsModel}
        // className="backlogs_move_model"
      >
        <div className='text-center'>
          <p className='mb-4 backlogs_move_content'>
            This task will move into Backlog items, Not consider to ready
            <br></br> to start at the moment, Kindly refine your backlog task
            <br></br> whenever you get free time
          </p>
          <button
            className='backlogs_go_btn'
            onClick={() => {
              const event = { target: { name: "backlogs", value: "Yes" } }
              onRadiobtnHandler(event)
              fetchViewBacklogssList(moveTrackingId)
              setShowAddBacklogsModal(!showAddBacklogstModal)
              // setddBacklogsSelect(true)
            }}
          >
            Go
          </button>
        </div>
      </UIModal>
    </TrackingViewWrap>
  )
}
