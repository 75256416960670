import React, { useEffect, useState, useRef } from "react"
import { Row, Col, Card, Button, Form, Modal } from "react-bootstrap"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { validateInput } from "../../../Utilities/validator"
import { WysiwygEditor } from "../WysiwygEditor"
import BarLoader from "react-spinners/ClipLoader"
import { DocUploader } from "../../Pages/Portal/Documents/DocUploader"
import { FileUploader } from "../FileUploader"
import { SelectBox } from "../SelectBox"
import { useNavigate } from "react-router-dom"
import { DatePicker } from "antd"
import { FormsWrapper } from "./style"
import AddNewuser from "../../Pages/Admin/User/AddNewUser"
import UIModal from "../Modal"
import CloseIcon from "@mui/icons-material/Close"
import { BsFillEyeFill } from "react-icons/bs"
import { AddNewProject } from "../../Pages/Portal/Projects/AddNewProject"
import Workflow from "../../Pages/Portal/Workflow"
import { useSelector } from "react-redux"
import { useFetch } from "../../../Hooks/useFetch"
import moment from "moment"
import { IconButton } from "@mui/material"
import { is } from "immutable"

export const Forms = ({
  title,
  formArr,
  FileURL,
  FileName,
  FileArr,
  template,
  submitBtn,
  setDocfiletype,
  onSubmit,
  formlinks,
  loading,
  allWorkFlows,
  allProjects,
  allMembers,
  cancelBtn,
  onCancel,
  classes,
  submitted,
  changeInForm,
  setChangeInForm,
  dueDtateStyle,
  backBtn,
  resetBtn,
  buttonclr,
  setTaskview,
  onResetEditor,
  setSelectedValue,
  getAllMembers,
  getAllProjects,
  showFileupload,
  setFileSize,
  quickTask,
  setShowAddNew,
  setFormvalue,
  newtask,
  formvalue
}) => {
  const prepareForm = (formArr) => {
    return formArr.reduce((r, v) => {
      if (v.type === "radio" && typeof v.defaultValue === "boolean") {
        return { ...r, [v.name]: v.defaultValue }
      } else {
        return { ...r, [v.name]: v.defaultValue ? v.defaultValue : "" }
      }
    }, {})
  }

  /*************************** react state  intiaalize *******************/
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  const selectRef = useRef()
  const [formAr, setFormAr] = useState(formArr)
  const [valid, setValid] = useState(false)
  const intialForm = prepareForm(formArr)
  const user = useSelector((state) => state.Auth.user)
  const [form, setform] = useState(intialForm)
  const [editorChanged, setEditorChanged] = useState(false)
  const [bgClr, setBgClr] = useState("#4EB6EE")
  const [Clr, setClr] = useState("#ffffff")
  const [borderClr, setborderClr] = useState("#4EB6EE")
  const [inputFileFieldReset, setInputFileFieldReset] = useState(false)
  const [editorState, setEditorState] = useState(false)
  const [inputval, setInputVal] = useState("")
  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")
  const [percentageVal, setPercentageVal] = useState("")
  const [showAddNewMember, setShowAddNewMember] = useState(false)
  const [showAddProjectModal, setShowAddProjectModal] = useState(false)
  const [showAddBacklogstModal, setShowAddBacklogsModal] = useState(false)
  const [AddBacklogstSelect, setddBacklogsSelect] = useState(false)
  const [showAddNewWorkflow, setShowAddNewWorkflow] = useState(false)
  const [selectedRadioValue, setSelectedRadioValue] = useState("No")
  const [showTemplate, setShowTemplate] = useState(false)
  const [backlogsBtn, setBacklogsBtn] = useState(false)
  const [templateModal, setTemplateModal] = useState()
  const [getTemplate, setGetTemplate] = useState("")
  const [showAllTemplates, setShowAllTemplates] = useState(false)
  const [passwordView, setPasswordView] = useState(false)
  const [confirmPasswordView, setConfirmPasswordView] = useState(false)

  const { data: allTemplateTableDataRes } = useFetch(
    "get-template",
    `/7_templatelist/${trackingClientId}`,
    () => { },
    () => { },
    false,
    false
  )
  let allTemplateTableData = allTemplateTableDataRes?.data ?? []

  let navigate = useNavigate()
  let forBacklogsModel = true
  /************** Functions ******************/
  useEffect(() => {
    if (onResetEditor) {
      setEditorState(true)
    }
  }, [onResetEditor])

  useEffect(() => {
    if (newtask) {
      setFormvalue(form.project_name)
    }
  }, [form])

  useEffect(() => {
    setFormAr((formarrs) => {
      return formarrs.map((formarr) => {
        if (formarr.name === "project_name") {
          return { ...formarr, options: allProjects }
        }
        return formarr
      })
    })
  }, [JSON.stringify(allProjects)])

  useEffect(() => {
    if (formvalue !== null || formvalue !== "") {
      setFormAr((formarrs) => {
        return formarrs.map((formarr) => {
          if (formarr.name === "assignedto") {
            return { ...formarr, options: allMembers }
          }
          return formarr
        })
      })
    }
  }, [formvalue])

  useEffect(() => {
    setFormAr((formarrs) => {
      return formarrs.map((formarr) => {
        if (formarr.name === "workflow") {
          return { ...formarr, options: allWorkFlows }
        }
        return formarr
      })
    })
  }, [JSON.stringify(allWorkFlows)])

  useEffect(() => {
    setFormAr((formarrs) => {
      return formarrs.map((formarr) => {
        if (formarr.name === "teammates") {
          return {
            ...formarr,
            options: allMembers.map((item) => {
              item.value = item.label
              return item
            })
          }
        }
        return formarr
      })
    })
  }, [JSON.stringify(allMembers)])

  const onChangeHandler = (e) => {
    setChangeInForm(true)
    if (e.slctbox) {
      setform((p) => ({ ...p, [e.fieldName]: e.id }))
    } else {
      setform((p) => ({ ...p, [e.target.name]: e.target.value }))
      formAr.forEach((item) => {
        let index = formAr.findIndex((item) => item.name === e.target.name)
        if (index !== undefined) formAr[index].value = e.target.value
      })
    }
  }
  const onResetHandler = () => {
    formArr.forEach((item) => {
      item.value = ""
    })
    setFormAr(formArr)
    setform(formArr.reduce((r, v) => ({ ...r, [v.name]: "" }), {}))
    setEditorChanged(false)
    setInputFileFieldReset(true)
    setEditorState(true)
    setInputVal("")
    setPercentageVal("")
    setStartDate("")
    setEndDate("")
  }

  const handleonClick = () => {
    setBgClr("#FFFFFF")
    setClr("#808080")
    setborderClr("#FFFFFF")
  }

  const onCheckboxHandler = (e) => {
    formAr?.forEach((value) => {
      if (value.name === e.target.name) {
        if (value.value) {
          value.value = false
          setform((p) => ({ ...p, [e.target.name]: false }))
        } else {
          value.value = true
          setform((p) => ({ ...p, [e.target.name]: true }))
        }
      }
    })
  }

  const onRadiobtnHandler = (e) => {
    setSelectedRadioValue(e.target.value)
    formAr?.forEach((value) => {
      if (value.name === e.target.name) {
        if (e.target.value === "Yes" || e.target.value === "No") {
          setform((p) => ({
            ...p,
            [e.target.name]: e.target.value
          }))
        } else if (e.target.value === true) {
          setform((prevState) => ({
            ...prevState,
            [e.target.name]: true
          }))
        } else if (e.target.value === false) {
          setform((prevState) => ({
            ...prevState,
            [e.target.name]: false
          }))
        }
      }
    })
  }

  const onSubmitHandle = () => {
    var keys = Object.values(form)
    let isValid = false
    keys?.forEach((item, index) => {
      formAr.forEach((item1, index1) => {
        if (index === index1) {
          let result = validateInput(item1.validators, item)
          formAr[index1].error = result.error
          formAr[index1].errormsg = result.message
          setFormAr(formAr)
          if (item1.match) {
            if (form[item1.match] !== item) {
              formAr[index].error = true
              formAr[index].errormsg = "Enter same password"
            }
          }
          if (formAr.find((item) => item.error === true)) {
            setValid(false)
            setBgClr("#4EB6EE")
            setClr("#FFFFFF")
            setborderClr("#4EB6EE")
            isValid = false
          } else {
            setValid(true)
            isValid = true
          }
          setEditorChanged(true)
        }
      })
    })
    onSubmit(form, isValid)
  }

  const onCancelHandle = (e) => {
    onCancel(changeInForm)
  }

  useEffect(() => {
    if (formAr?.find((item) => item.error == true)) setValid(false)
  }, [form])

  // useEffect(() => {
  //   if (valid) {
  //     onSubmit(form, valid)
  //     setEditorChanged(true)
  //   }
  // }, [valid])

  useEffect(() => {
    formAr?.forEach((item) => {
      item.value = ""
    })
    setFormAr(formAr)
    setform(
      formArr.reduce((r, v) => {
        if (v.type === "checkbox" && typeof v.defaultValue === "boolean") {
          return { ...r, [v.name]: v.defaultValue }
        } else {
          return { ...r, [v.name]: v.defaultValue ? v.defaultValue : "" }
        }
      }, {})
    )
    setEditorChanged(false)
  }, [submitted])

  const handleSplChar = (e) => {
    if (
      e.key === "#" ||
      e.key === "$" ||
      e.key === "^" ||
      e.key === "@" ||
      e.key === "*" ||
      e.key === "(" ||
      e.key === "!" ||
      e.key === ")" ||
      e.key === "%" ||
      e.key === "="
    ) {
      e.preventDefault()
    }
  }

  const handleAlpha = (e) => {
    const re = /^[A-Za-z]+$/
    if (e.key === " " || re.test(e.key)) {
      return false
    }
    e.preventDefault()
  }

  const handleAmount = (e) => {
    const result = e.target.value.replace(/[^0-9,.]/gi, "")
    setInputVal(result)
  }

  const handlePercentage = (e) => {
    const result = e.target.value.replace(/[^0-9.]/gi, "")
    const parts = result.split(".")
    const integerPart = parts[0].substring(0, 3)
    let decimalPart = ""
    if (parts.length > 1) {
      decimalPart = "." + parts[1]
    }
    const formattedResult = integerPart + decimalPart
    setPercentageVal(formattedResult)
  }

  useEffect(() => {
    if (loading === false) {
      setBgClr("#4EB6EE")
      setClr("#FFFFFF")
      setborderClr("#4EB6EE")
    }
  }, [loading])

  const onTemplateEditorHandler = (value) => {
    setform((prevForm) => ({
      ...prevForm,
      description: value
    }))
  }

  return (
    <FormsWrapper>
      <Card className='border-0'>
        <Card.Body className={"loginformbody formBody " + classes}>
          <Form
            autoComplete='off'
            onSubmit={(e) => {
              e.preventDefault()
              e.stopPropagation()
              handleonClick()
              onSubmitHandle()
            }}
          >
            {title !== "" ? <h5 className='formScreenUser mb-0 pb-3'>{title}</h5> : <></>}
            <Row className='mb-2'>
              {formAr.map(
                (
                  {
                    label,
                    halfWidth,
                    mandatory,
                    name,
                    type,
                    placeholder,
                    validators,
                    error,
                    errormsg,
                    options,
                    rows,
                    value,
                    defaultValue,
                    maxLength,
                    max,
                    match,
                    disable,
                    checkbox_label,
                    autoFocus,
                    onlyAssin,
                    assignedToButton,
                    templateDescription
                  },
                  index
                ) => {
                  const validateField = (e, match) => {
                    let result = validateInput(validators, e.target.value)
                    formAr[index].error = result.error
                    if (e.preventDefault) e.preventDefault()
                    formAr[index].errormsg = result.message
                    setFormAr(formAr)
                    if (match) {
                      if (form[match] !== e.target.value) {
                        formAr[index].error = true
                        formAr[index].errormsg = "Enter same password"
                      }
                    }
                  }
                  const onChangeEditorHandler = (value) => {
                    setform((prevForm) => ({
                      ...prevForm,
                      description: value
                    }))
                  }
                  return (
                    <React.Fragment key={index}>
                      {halfWidth ? (
                        <Col
                          sm={6}
                          key={index + 1}
                          className={
                            label === "Would you like to move to backlogs?" ||
                              label === "Would you like to Set This Project as Priority"
                              ? "backlogs_section_header"
                              : ""
                          }
                        >
                          <Form.Group
                            className={
                              label === "Would you like to move to backlogs?" ||
                                label === "Would you like to Set This Project as Priority"
                                ? "backlogs_radio_header"
                                : ""
                            }
                          >
                            {label !== "" ? (
                              <Form.Label>
                                {label}
                                {mandatory ? <></> : <span className='mandatorySign'> * </span>}
                              </Form.Label>
                            ) : (
                              <></>
                            )}
                            {type === "startdate" ? (
                              <DatePicker
                                format={"DD/MM/YYYY"}
                                // showTime={{ format: "hh:mm A" }}
                                className='form-control start_date'
                                name={name}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  borderRadius: "5px"
                                }}
                                placeholder={placeholder}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode
                                }}
                                value={startDate ? startDate : null}
                                disabledDate={(current) => {
                                  return current && endDate && current > endDate;
                                }}
                                onChange={(date) => {
                                  const e = {
                                    target: {
                                      name: name,
                                      value: date
                                        ? moment(date.$d).format("DD/MM/YYYY hh:mm A")
                                        : ""
                                    }
                                  }
                                  date !== (null || "") && onChangeHandler(e)
                                  validateField(e)
                                  date ? setStartDate(date) : setStartDate("")
                                }}
                              />
                            ) : type === "enddate" ? (
                              <DatePicker
                                format={"DD/MM/YYYY"}
                                // showTime={{ format: "hh:mm A" }}
                                className='form-control end_date'
                                selected={endDate}
                                name={name}
                                style={{
                                  width: "100%",
                                  height: "38px",
                                  borderRadius: "5px"
                                }}
                                value={endDate ? endDate : null}
                                placeholder={placeholder}
                                getPopupContainer={(triggerNode) => {
                                  return triggerNode.parentNode
                                }}
                                disabledDate={(current) => {
                                  return current && startDate && current < startDate.startOf('day')
                                }}
                                onChange={(date) => {
                                  const e = {
                                    target: {
                                      name: name,
                                      value: date
                                        ? moment(date.$d).format("DD/MM/YYYY hh:mm A")
                                        : ""
                                    }
                                  }
                                  date !== (null || "") && onChangeHandler(e)
                                  validateField(e)
                                  date ? setEndDate(date) : setEndDate("")
                                }}
                              />
                            ) : type === "select" ? (
                              <Form.Control
                                as='select'
                                name={name}
                                value={defaultValue ? defaultValue : value}
                                disabled={disable}
                                onChange={(e) => {
                                  onChangeHandler(e)
                                  validateField(e)
                                }}
                              >
                                {defaultValue ? (
                                  <option value={defaultValue}>{defaultValue}</option>
                                ) : (
                                  <option value=''> Select </option>
                                )}

                                {options.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.label}
                                  </option>
                                ))}
                              </Form.Control>
                            ) : type === "text" ? (
                              <Col>
                                <Form.Control
                                  className='col-title'
                                  type={type}
                                  placeholder={placeholder}
                                  disabled={disable}
                                  value={value}
                                  name={name}
                                  maxLength={maxLength}
                                  onChange={(e) => {
                                    onChangeHandler(e)
                                    validateField(e)
                                  }}
                                />
                              </Col>
                            ) : type === "multiselect" ? (
                              <Col>
                                <SelectBox
                                  selectRef={selectRef}
                                  placeholder={placeholder}
                                  value={value}
                                  options={options.map((item) => {
                                    item.value = item.label
                                    return item
                                  })}
                                  onChange={(event) => {
                                    if (event.some((e) => e.id === "Add New User")) {
                                      setShowAddNewMember(true)
                                      return
                                    }
                                    onChangeHandler({
                                      target: { name, value: event }
                                    })
                                  }}
                                  isMulti
                                />
                              </Col>
                            ) : type === "budget" ? (
                              <Form.Control
                                className='col-title'
                                type='text'
                                placeholder={placeholder}
                                disabled={disable}
                                value={inputval}
                                name={name}
                                max={max}
                                maxLength={maxLength}
                                onChange={(e) => {
                                  onChangeHandler(e)
                                  handleAmount(e)
                                  validateField(e)
                                }}
                              />
                            ) : type === "Percentage" ? (
                              <Form.Control
                                className='col-title'
                                type='tel'
                                placeholder={placeholder}
                                disabled={disable}
                                value={percentageVal}
                                name={name}
                                max={max}
                                maxLength={maxLength}
                                onChange={(e) => {
                                  onChangeHandler(e)
                                  handlePercentage(e)
                                  validateField(e)
                                }}
                              />
                            ) : type === "selectbox" ? (
                              <div className={onlyAssin && "for_assigned_to"}>
                                <SelectBox
                                  ref={selectRef}
                                  options={options}
                                  placeholder={placeholder}
                                  value={value === "" ? defaultValue : value}
                                  name={value}
                                  quickTask={quickTask}
                                  onlyAssin={onlyAssin}
                                  onChange={(e) => {
                                    if (e.id === "Add New User") {
                                      setShowAddNewMember(true)
                                      e = ""
                                      return
                                    }
                                    if (e.id === "Add New Project") {
                                      setShowAddProjectModal(true)
                                      e = ""
                                      return
                                    }
                                    if (e.id === "Add New WorkFlow") {
                                      setShowAddNewWorkflow(true)
                                      e = ""
                                      return
                                    }

                                    onChangeHandler({
                                      target: { name, value: e }
                                    })
                                    validateField({
                                      target: { name, value: e }
                                    })
                                  }}
                                  submitted={submitted}
                                />
                                <div>{assignedToButton}</div>
                              </div>
                            ) : type === "radio" ? (
                              <>
                                <div className='backlogs_radio_btns'>
                                  <div>
                                    <Form.Check
                                      controlId='formBasicEmail'
                                      label='Yes'
                                      name={name}
                                      type={type}
                                      value='Yes'
                                      checked={selectedRadioValue === "Yes"}
                                      onChange={(e) => {
                                        setShowAddBacklogsModal(true)
                                      }}
                                    />
                                  </div>

                                  <div>
                                    <Form.Check
                                      controlId='formNoCheckBox'
                                      label='No'
                                      name={name}
                                      type={type}
                                      value='No'
                                      checked={selectedRadioValue === "No"}
                                      onChange={(e) => onRadiobtnHandler(e)}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : type === "priority-radio" ? (
                              <>
                                <div className='backlogs_radio_btns'>
                                  <div>
                                    <Form.Check
                                      controlId='form-basic-radio'
                                      label='Yes'
                                      name={name}
                                      type={"radio"}
                                      value='Yes'
                                      checked={selectedRadioValue === "Yes"}
                                      onChange={(e) => {
                                        onRadiobtnHandler(e)
                                      }}
                                    />
                                  </div>

                                  <div>
                                    <Form.Check
                                      controlId='form-basic-radio'
                                      label='No'
                                      name={name}
                                      type={"radio"}
                                      value='No'
                                      checked={selectedRadioValue === "No"}
                                      onChange={(e) => onRadiobtnHandler(e)}
                                    />
                                  </div>
                                </div>
                              </>
                            ) : type === "priority-radio" ? (
                              <>
                                {user.userRole === "Admin" && (
                                  <div className='backlogs_radio_btns'>
                                    <div>
                                      <Form.Check
                                        controlId='form-priority-radio-yes'
                                        label='Yes'
                                        name={name}
                                        type={type}
                                        value='Yes'
                                        checked={selectedRadioValue === "Yes"}
                                        onChange={(e) => {
                                          setShowAddBacklogsModal(true)
                                        }}
                                      />
                                    </div>

                                    <div>
                                      <Form.Check
                                        controlId='form-priority-radio-no'
                                        label='No'
                                        name={name}
                                        type={type}
                                        value='No'
                                        checked={selectedRadioValue === "No"}
                                        onChange={(e) => onRadiobtnHandler(e)}
                                      />
                                    </div>
                                  </div>
                                )}
                              </>
                            ) : type === "checkbox" ? (
                              <Form.Check
                                type='checkbox'
                                className='isPriority_check'
                                name={name}
                                checked={defaultValue ? defaultValue : value}
                                value={defaultValue ? defaultValue : value}
                                label={checkbox_label}
                                onChange={(e) => onCheckboxHandler(e)}
                              />
                            ) : (
                              <></>
                            )}
                          </Form.Group>
                          {error ? (
                            <small className='error-message d-block'>{errormsg}</small>
                          ) : (
                            <></>
                          )}
                        </Col>
                      ) : (
                        <Col sm={12} key={index}>
                          <Form.Group controlId={name}>
                            {label !== "" ? (
                              <Form.Label>
                                {label}
                                {mandatory ? <></> : <span className='mandatorySign'> * </span>}
                              </Form.Label>
                            ) : (
                              <></>
                            )}
                            {type === "text" ? (
                              <Form.Control
                                className='ps-2'
                                type={type}
                                autoFocus={autoFocus}
                                placeholder={placeholder}
                                disabled={disable}
                                value={value}
                                name={name}
                                onKeyDown={handleSplChar}
                                maxLength={maxLength}
                                onChange={(e) => {
                                  onChangeHandler(e)
                                  validateField(e)
                                }}
                              />
                            ) : (
                              <></>
                            )}
                            {type === "onlytext" ? (
                              <Form.Control
                                type={type}
                                placeholder={placeholder}
                                disabled={disable}
                                value={value}
                                name={name}
                                onKeyDown={handleAlpha}
                                maxLength={maxLength}
                                onChange={(e) => {
                                  onChangeHandler(e)
                                  validateField(e)
                                }}
                              />
                            ) : type === "password" ? (
                              <div className='position-relative'>
                                <Form.Control
                                  maxLength={30}
                                  type={passwordView ? "text" : type}
                                  placeholder={placeholder}
                                  disabled={disable}
                                  value={value}
                                  name={name}
                                  onChange={(e) => {
                                    onChangeHandler(e)
                                    validateField(e, match)
                                  }}
                                />
                                {value !== "" && (
                                  <IconButton className='password_view_icon'>
                                    <BsFillEyeFill
                                      onClick={(e) => setPasswordView(!passwordView)}
                                    />
                                  </IconButton>
                                )}
                              </div>
                            ) : type === "confirm-password" ? (
                              <div className='position-relative'>
                                <Form.Control
                                  maxLength={30}
                                  type={confirmPasswordView ? "text" : "password"}
                                  placeholder={placeholder}
                                  disabled={disable}
                                  value={value}
                                  name={name}
                                  onChange={(e) => {
                                    onChangeHandler(e)
                                    validateField(e, match)
                                  }}
                                />
                                {value !== "" && (
                                  <IconButton className='password_view_icon'>
                                    <BsFillEyeFill
                                      onClick={(e) => setConfirmPasswordView(!confirmPasswordView)}
                                    />
                                  </IconButton>
                                )}
                              </div>
                            ) : type === "email" || type === "number" || type === "file" ? (
                              <Form.Control
                                type={type}
                                placeholder={placeholder}
                                disabled={disable}
                                value={value}
                                name={name}
                                onChange={(e) => {
                                  onChangeHandler(e)
                                  validateField(e)
                                }}
                              />
                            ) : type === "select" ? (
                              <Form.Control
                                as='select'
                                name={name}
                                value={value}
                                disabled={disable}
                                onChange={(e) => {
                                  onChangeHandler(e)
                                  validateField(e)
                                }}
                              >
                                <option value=''>Select</option>
                                {options.map((item, index) => (
                                  <option value={item.id} key={index}>
                                    {item.label}
                                  </option>
                                ))}
                              </Form.Control>
                            ) : type === "selectbox" ? (
                              <SelectBox
                                ref={selectRef}
                                options={options}
                                placeholder={placeholder}
                                value={value === "" ? defaultValue : value}
                                name={value}
                                onChange={(e) => {
                                  onChangeHandler({
                                    target: { name, value: e }
                                  })
                                  validateField({
                                    target: { name, value: e }
                                  })
                                }}
                                submitted={submitted}
                              />
                            ) : type === "textarea" ? (
                              <>
                                <div
                                  className={templateDescription ? "for_template_description" : ""}
                                >
                                  <WysiwygEditor
                                    formAr={formAr}
                                    value={value}
                                    onChange={onChangeEditorHandler}
                                    changed={editorChanged}
                                    resetEditorState={editorState}
                                    setEditorState={setEditorState}
                                    index={index}
                                    setFormAr={setFormAr}
                                    validators={validators}
                                    getTemplate={getTemplate}
                                  />
                                </div>
                              </>
                            ) : type === "uploadfile" ? (
                              <>
                                <FileUploader
                                  name={name}
                                  value={value}
                                  FileName={FileName}
                                  FileArr={FileArr}
                                  FileURL={FileURL}
                                  submitted={submitted}
                                  setDocfiletype={setDocfiletype}
                                  resetField={inputFileFieldReset}
                                  setInputFileFieldReset={setInputFileFieldReset}
                                  showFileupload={showFileupload}
                                  setFileSize={setFileSize}
                                />
                              </>
                            ) : type === "docuploader" ? (
                              <>
                                <DocUploader
                                  name={name}
                                  value={value}
                                  FileName={FileName}
                                  FileArr={FileArr}
                                  FileURL={FileURL}
                                  submitted={submitted}
                                  setDocfiletype={setDocfiletype}
                                  resetField={inputFileFieldReset}
                                  setInputFileFieldReset={setInputFileFieldReset}
                                  showFileupload={showFileupload}
                                  setFileSize={setFileSize}
                                />
                              </>
                            ) : type === "checkbox" ? (
                              <></>
                            ) : (
                              /*****************the below commended code is need for next deployment************/
                              // <>
                              //   {/* <Form.Check
                              //       type="checkbox"
                              //       name={name}
                              //       checked={value}
                              //       value={value}
                              //       label={checkbox_label}
                              //       onChange={(e) => onCheckboxHandler(e)}
                              //     /> */}
                              //   <p className="text-white">Select a template</p>
                              //   <div className="template_conatiner">
                              //     {allTemplateTableData?.slice(0, 4).map((item) => {
                              //       const TempalteBox =
                              //         <>
                              //           <div className="template_content_container">
                              //             <div className="template_content_box" onClick={() => { setShowTemplate(!showTemplate); setTemplateModal(TempalteBox) }}>
                              //               <div className="template_view_icon"><BsFillEyeFill /></div>
                              //               <span dangerouslySetInnerHTML={{ __html: item.notes }} className="developer_notes_content" />
                              //             </div>
                              //           </div>
                              //         </>
                              //       return (
                              //         <>
                              //           <div className="template_content_container">
                              //             {TempalteBox}
                              //             <div className="template_select_name">{item.templatetitle}</div>
                              //           </div>
                              //         </>
                              //       )
                              //     })}
                              //     {allTemplateTableData?.length > 4 && !showAllTemplates && (
                              //       <div
                              //         className="form_template_view_more"
                              //         onClick={() => setShowAllTemplates(true)}
                              //       >
                              //         View more...
                              //       </div>
                              //     )}
                              //     <div className="view_more_container">
                              //       {showAllTemplates &&
                              //         <>
                              //           {allTemplateTableData?.slice(4).map((item) => {
                              //             const TemplateBox = (
                              //               <div className="template_content_container">
                              //                 <div
                              //                   className="template_content_box"
                              //                   onClick={() => {
                              //                     setShowTemplate(!showTemplate);
                              //                     setTemplateModal(TemplateBox);
                              //                   }}
                              //                 >
                              //                   <div className="template_view_icon"><BsFillEyeFill /></div>
                              //                   <span dangerouslySetInnerHTML={{ __html: item.notes }} />
                              //                 </div>
                              //                 <div className="template_select_name">{item.templatetitle}</div>
                              //               </div>
                              //             );
                              //             return TemplateBox;
                              //           })}
                              //           <div
                              //             className="form_template_view_more"
                              //             onClick={() => setShowAllTemplates(false)}
                              //           >
                              //             View less...
                              //           </div>
                              //         </>
                              //       }
                              //     </div>
                              //   </div>
                              // </>
                              <></>
                            )}
                          </Form.Group>
                          {error ? (
                            <small
                              style={{
                                translate: type === "textarea" && "0px -15px"
                              }}
                              className='error-message d-block'
                            >
                              {errormsg}
                            </small>
                          ) : (
                            <></>
                          )}
                        </Col>
                      )}
                    </React.Fragment>
                  )
                }
              )}
            </Row>
            {formlinks}

            <Row className='login'>
              <Col className='text-center btn-padding' sm={12}>
                {resetBtn ? (
                  <Button
                    type='button'
                    onClick={onResetHandler}
                    className='resetbtn'
                    variant='secondary'
                  >
                    Reset
                  </Button>
                ) : (
                  <></>
                )}
                {backBtn ? (
                  <Button
                    type='button'
                    onClick={() => navigate(-1)}
                    className='backbtn'
                    variant='secondary'
                  >
                    Back
                  </Button>
                ) : (
                  <></>
                )}
                {cancelBtn ? (
                  <Button
                    type='button'
                    onClick={(e) => {
                      onCancelHandle(e)
                      !quickTask && setTaskview("AllTask")
                      quickTask && setShowAddNew(false)
                    }}
                    className={`${quickTask ? "quickCancel" : "cancelbtn loginCancelBtn"
                      } mx-2 my-2 `}
                    variant='secondary'
                    style={{ background: buttonclr }}
                  >
                    Cancel
                  </Button>
                ) : (
                  <></>
                )}

                <Button
                  type='submit'
                  className={`${quickTask ? "quickTask" : "loginSubmitBtn"} `}
                  style={{
                    background: bgClr,
                    color: Clr,
                    borderColor: borderClr
                  }}
                >
                  {loading ? (
                    <BarLoader
                      className='bar-loader'
                      color='#808080'
                      size={14}
                      role='status'
                      aria-hidden='true'
                    />
                  ) : (
                    <></>
                  )}
                  {submitBtn}
                </Button>
              </Col>
            </Row>
          </Form>
        </Card.Body>
      </Card>

      <UIModal
        title={"Add New Workflow"}
        showModal={showAddNewWorkflow}
        setShowModal={setShowAddNewWorkflow}
      >
        <Workflow
          showAddNewWorkflow={showAddNewWorkflow}
          setShowAddNewWorkflow={setShowAddNewWorkflow}
        />
      </UIModal>

      <UIModal
        title={"Add New Project"}
        showModal={showAddProjectModal}
        setShowModal={setShowAddProjectModal}
      >
        <AddNewProject
          setProjectView={() => {
            setShowAddProjectModal(false)
          }}
          getAllProjects={getAllProjects}
          setShowAlertBox={() => { }}
          setAlertMessage={() => { }}
          onTaskView={true}
          taskViewPanel=''
          updateAllProject={() => { }}
        />
      </UIModal>

      <AddNewuser
        showAddUser={showAddNewMember}
        CloseAddUser={() => {
          setShowAddNewMember(false)
        }}
        getAllMembers={getAllMembers}
        setShowNoUser={() => { }}
      />
      <Modal
        show={showTemplate}
        onHide={() => setShowTemplate(false)}
        className='template_modal_container'
      >
        <CloseIcon
          className='close-icon template_close_icon'
          onClick={() => setShowTemplate(false)}
          style={{ cursor: "pointer", fontSize: "30px" }}
        />
        <div className='bg-white template_modal_body'>
          {templateModal}
          <div>
            <p className='template_select_content'>
              This template can be used for<br></br> multipurpose by<br></br> developers, testers,
              admin, etc
            </p>
            <button
              className='template_select_btn'
              onClick={() => {
                onTemplateEditorHandler()
                setGetTemplate(templateModal)
                setShowTemplate(false)
              }}
            >
              Use template
            </button>
          </div>
        </div>
      </Modal>

      <UIModal
        showModal={showAddBacklogstModal}
        setShowModal={setShowAddBacklogsModal}
        size='md'
        forBacklogsModel={forBacklogsModel}
      // className="backlogs_move_model"
      >
        <div className='text-center'>
          <p className='mb-4 backlogs_move_content'>
            This task will move into Backlog items, Not consider to ready
            <br></br> to start at the moment, Kindly refine your backlog task
            <br></br> whenever you get free time
          </p>
          <button
            className='backlogs_go_btn'
            onClick={() => {
              const event = { target: { name: "backlogs", value: "Yes" } }
              onRadiobtnHandler(event)
              setShowAddBacklogsModal(!showAddBacklogstModal)
              setddBacklogsSelect(true)
            }}
          >
            Go
          </button>
        </div>
      </UIModal>
    </FormsWrapper>
  )
}
