import React, { useState } from "react"
import { useSelector } from "react-redux"
import { Row } from "react-bootstrap"
import Tables from "../../../../Reuseable/Tables"
import "react-confirm-alert/src/react-confirm-alert.css"
import MydashboardModalView from "./style"
import moment from "moment"
import DoughnutChart from "./ProjectDoughnut"
import { ProjectDashboradBarChart } from "./ProjectDashboradBarChart"
import ProjectEditableComponents from "./ProjectEditableComponents"
import { ProjectVersions } from "./ProjectVersions"
import UIModal from "../../../../Reuseable/Modal"
import { ProjectTaskList } from "./ProjectTaskList"
import ProjectMyTeam from "./ProjectMyTeam"
import { useFetch } from "../../../../../Hooks/useFetch"
import { key } from "../../../../../data/queryKeys"

export default function ProjectDashboard({
  showProject,
  setShowProject,
  permission,
  datas,
  version,
  allProjects,
  projectTaskList,
  loading
}) {
  let data = datas
  // const editPermission = permission?.edit
  // const createPermission = permission?.create
  // const deletePermission = permission.delete
  // const viewPermission = permission.view

  const authToken = useSelector((state) => state.Auth.authToken)
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)

  let allProjectDashboard = useSelector((state) => state.Tracking.allProjectDashboard)
  const [filteredTasks, setFilteredTasks] = useState([])
  const [showTaskCountsModal, setShowTaskCountsModal] = useState(false)
  const [showTaskStatus, setShowTaskStatus] = useState("")
  let projectNow = allProjects?.find((item) => item.trackingprojectid === data)

  const index = allProjects?.findIndex(
    (element) => element.trackingprojectid === projectNow?.trackingprojectid
  )

  const { data: allProjectWorkflowRes } = useFetch(
    key.get_all_workflow,
    `/trekrworkflow`,
    () => {},
    () => {},
    false,
    false
  )
  let allWorkFlow = allProjectWorkflowRes?.data?.workflowList ?? []

  const { data: allMembersRes } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allMembersRes?.data ?? []

  let convertedMonthTypeCounts = {}

  allProjectDashboard !== "" &&
    allProjectDashboard &&
    Object.keys(allProjectDashboard?.monthTypeCounts || {})?.forEach((month) => {
      const convertedCounts = {}

      allProjectDashboard !== "" &&
        allProjectDashboard &&
        Object.keys(allProjectDashboard?.monthTypeCounts[month] || {}).forEach((key) => {
          let convertedKey = ""

          switch (key) {
            case "1":
              convertedKey = "Bug/Issue"
              break
            case "2":
              convertedKey = "New Task"
              break
            case "3":
              convertedKey = "Request for Change"
              break
            case "4":
              convertedKey = "Other"
              break
            default:
              convertedKey = "Unknown"
              break
          }

          convertedCounts[convertedKey] = allProjectDashboard.monthTypeCounts[month][key]
        })

      convertedMonthTypeCounts[month] = convertedCounts
    })

  const priorityCount = allProjectDashboard?.priorityCount
  const Work_Flow1 = allWorkFlow && Object.values(allWorkFlow).flatMap((obj) => Object.values(obj))

  let workflow_status = Work_Flow1?.find((e) => e.workFlowId === allProjectDashboard.workflowid)
  const statusName = workflow_status?.workFlow
  allProjectDashboard.workflow_status = statusName && Object.values(statusName)

  let statusTaskCount = allProjectDashboard.statusIdCount
  let workflowName = allProjectDashboard.workflow_status

  let versiondata = statusTaskCount
    ?.sort((a, b) => parseInt(a.name) - parseInt(b.name))
    .map((item) => {
      let myYdata = workflowName?.[item?.name]
      let newY = myYdata ? Object.values(myYdata)[0]?.toString() : ""
      return { name: newY, value: item.value, id: item.name }
    })

  const getTableData = projectNow?.teammates?.map((item, i) => {
    let hex = Math.floor(Math.random() * 0xffffff)
    let color = "#" + hex.toString(16)

    return (
      <tr key={i} val={item}>
        <td>
          <div className='d-flex align-items-center'>
            <div className='team-name-circle' style={{ background: color }}>
              <span>{item.label[0]}</span>
            </div>
            <div className='ms-2'>{item.label}</div>
          </div>
        </td>
      </tr>
    )
  })

  const taskColumns = (
    <>
      <th className='text-nowrap'>
        <span className='ps-1'>Task No</span>
      </th>
      <th className='text-nowrap'>Title</th>
      <th className='text-nowrap'>Timeline</th>
      <th className='text-nowrap'>Reported On</th>
      <th className='text-nowrap'>Reported By</th>
      <th className='text-nowrap'>Assigned To</th>
      <th className='text-nowrap'>Priority</th>
    </>
  )

  const openTaskModal = (id) => {
    let filtered = () => {
      return projectTaskList
        .filter((item) => {
          if (item.trackingprojectstatusid === id) {
            return item
          }
        })
        .map((item, i) => {
          let reportedby = allMembers
            .filter((member) => member.userId === item.reportedby)
            .map((member) => member.personname)
            .join(", ")

          let assignedTo = allMembers
            .filter((member) => member.userId === item.assignedto)
            .map((member) => member.personname)
            .join(", ")

          if (assignedTo === "" || assignedTo === "-") {
            assignedTo = "Not Assigned"
          }

          return (
            <tr key={i} val={item}>
              <td className='p-0'>
                {/* <strong className="mobHead">Task No :</strong> */}
                {item.trackingclientid}-{item.trackingid}
              </td>
              <td className='text-nowrap'>
                {/* <strong className="mobHead">Title :</strong> */}
                <span>{item?.title}</span>
              </td>
              <td className='text-capitalize text-nowrap'>
                {/* <strong className="mobHead">Timeline: </strong> */}
                <span>{item.timeline === "" ? "-" : item.timeline}</span>
              </td>
              <td className='text-capitalize text-nowrap'>
                {/* <strong className="mobHead">Reported On: </strong> */}
                <span>
                  {item.reporteddate === ""
                    ? "-"
                    : moment(item.reporteddate).format("MMM D, YYYY hh:mm A")}
                </span>
              </td>
              <td className='text-capitalize text-nowrap'>
                {/* <strong className="mobHead">Reported By: </strong> */}
                <span>{reportedby}</span>
              </td>
              <td className='text-capitalize text-nowrap'>
                {/* <strong className="mobHead">Assigned To: </strong> */}
                <span>{assignedTo}</span>
              </td>
              <td className='text-nowrap'>
                {/* <strong className="mobHead">Priority : </strong> */}
                <span className='text-capitalize'>
                  {item.priority === "blocker"
                    ? "Blocker Level 1"
                    : item.priority === "blocker2"
                    ? "Blocker Level 2"
                    : item.priority === ""
                    ? "-"
                    : item.priority}
                </span>
              </td>
            </tr>
          )
        })
    }
    setFilteredTasks(filtered)
    setShowTaskCountsModal(true)
  }

  return (
    <>
      <MydashboardModalView>
        {loading ? (
          <></>
        ) : (
          <>
            {version === "Dashboard" ? (
              <>
                <Row className='align-items-center mb-3 ms-0 total_chart_container'>
                  <div className='p-3 bar_chart_container'>
                    <div>
                      <ProjectDashboradBarChart
                        convertedMonthTypeCounts={convertedMonthTypeCounts}
                        monthTypeCounts={allProjectDashboard.monthTypeCounts}
                      />
                    </div>
                  </div>
                  <div className='doughnut_chart'>
                    <DoughnutChart
                      priorityCount={priorityCount}
                      allProjectDashboard={allProjectDashboard}
                    />
                  </div>
                </Row>

                {versiondata?.length > 0 ? (
                  <>
                    <h5 className='project_sub_head'>Task Status</h5>
                    <div className='task_status'>
                      {versiondata?.map((item, i) => {
                        return (
                          <div className=' mb-3' key={i}>
                            <div
                              className='project_card p-3'
                              onClick={() => {
                                openTaskModal(item.id)
                                setShowTaskStatus(item.name)
                              }}
                            >
                              <p className='mt-1'>{item.name}</p>
                              <h1 className='mb-0'>{item.value}</h1>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <ProjectEditableComponents
                  showProject={showProject}
                  permission={permission}
                  datas={datas}
                  allProjects={allProjects}
                />
                <ProjectMyTeam
                  showProject={showProject}
                  setShowProject={setShowProject}
                  permission={permission}
                  datas
                  projectNow={projectNow}
                  allProjects={allProjects}
                />

                <br></br>
              </>
            ) : version === "List" ? (
              <>
                <ProjectTaskList projectTaskList={projectTaskList} loading={loading} />
              </>
            ) : (
              <ProjectVersions data={getTableData} projectNow={projectNow} />
            )}
          </>
        )}
        <UIModal
          className='project_dashboard_modal'
          title={`${showTaskStatus} Tasks`}
          showModal={showTaskCountsModal}
          setShowModal={setShowTaskCountsModal}
          size={"xl"}
        >
          <Tables
            setProjectTable={true}
            columns={taskColumns}
            data={filteredTasks}
            showInfiniteScroll={false}
            notfound={"No Tasks Found"}
            loading={loading}
          />
        </UIModal>
      </MydashboardModalView>
    </>
  )
}
