import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Dropdown, Button, Spinner } from "react-bootstrap"
import OverlayTrigger from "react-bootstrap/OverlayTrigger"
import Tooltip from "react-bootstrap/Tooltip"
import Searchbox from "../../../../Reuseable/Searchbox"
import { BiArchiveIn } from "react-icons/bi"
import {
  setAllTrackings,
  setArchiveTableData,
  setFilters,
  setalltask
} from "../../../../../redux/Tracking/actionCreator"
import RefreshIcon from "@mui/icons-material/Refresh"
import { ReactComponent as BacklogsIcon } from "../../../../../assets/images/icons/backlogs-icon.svg"
import download from "../../../../../assets/images/icons/download1.svg"
import filter from "../../../../../assets/images/icons/filter.svg"
import filtered from "../../../../../assets/images/icons/filtered.svg"
import plus from "../../../../../assets/images/icons/plus.svg"
import { MultiSelect } from "../../../../Reuseable/MultiSelect"
import Styled from "styled-components"
import moment from "moment"
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth"
import { api } from "../../../../../Services/api"
import { usePrevious } from "react-use"
import { useFetch } from "../../../../../Hooks/useFetch"
import { key } from "../../../../../data/queryKeys"
import { client } from "../../../../.."
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"

const TrackingHeaderWrap = Styled.div`
  .filters_actions_section{
    display: flex;
    justify-content: end;
    align-items: center;
    flex-wrap: wrap;
  }
  .move_button_section{
    display: flex;
  }
  .actions_move_button{
    font-size: 18px;
    padding: 0px 0px;
    background-color: #4EB6EE;
    border: none;
    width: 28px;
    height: 28px;
    outline: none;
    border-radius: 5px;
    margin-right: 6px;
  }
  .moved_icons{
    font-size: 20px;
    color: #fff;
    path{
      font-size: 20px;
      fill: #fff;
    }
  }
  .filter_drop_tog{
    padding: 0px 0px;
    background-color: transparent;
    border-color: transparent;
  }
  .menu_dropdown{
    justify-content: space-between;
    border-bottom: 1px solid #f2f2f2;
  }
  .clear_all_btn{
    color: #4EB6EE;
  }
  .download_btn{
    padding: 0px 0px;
    background-color: transparent;
    border-color: transparent;
    cursor: 'pointer';
  }
  .refresh_btn{
    padding: 0px 0px;
    background-color: #4EB6EE;
    border-color: #4EB6EE;
    width: 28px;
    height: 28px;
    color: #ffffff;
  }
  .react-datepicker__close-icon::after {
    background-color: #80808063;
  }
  .add_task_btn{
    padding: 0px 0px;
    background-color: transparent;
    border-color: transparent;
    cursor: 'pointer';
  }
  .filter_container{
    min-width: 500px;
  }

  .calender-icon{
    font-size: 15px;
    margin-top: 3px;
    color: #00000057;
  }

  .filterbtn{
      cursor: "pointer";
      background: rgb(78, 182, 238);
      color: rgb(255, 255, 255);
      border-color: rgb(78, 182, 238);
      margin-left: 74%;
      margin-top: 10px;
      &:active{
        background-color: rgb(78, 182, 238);
        border-color: rgb(78, 182, 238);
      }
  }

  @media (max-width: 500px) 
  {
    .filterbtn{
      margin-left: 50%;
    }
  }
`

export const TrackingListHeader = ({
  searchValue,
  handleSearch,
  cardView,
  fetchAllTracking,
  refreshAllTracking,
  setLoading,
  loading,
  filterloading,
  trackingData,
  setShowFormView,
  setFormType,
  setChangeInForm,
  showFilter,
  trackingFilters,
  setTrackingFilters,
  setTaskview,
  selectedProject,
  createPermission,
  fromDate,
  toDate,
  setFromDate,
  setToDate,
  allcheckTable,
  setShowAlertBox,
  setAlertMessage,
  setAllCheckTable,
  backlogsPermission,
  menu,
  fetchTrackingFilter,
  data
}) => {
  let items = []
  if (menu === "my_tasks") {
    trackingData.forEach((element) => {
      element.reporteddate = moment(element.reporteddate).format("MMM D, YYYY h:mm A")
      items.push(element)
    })
  } else {
    data?.forEach((element) => {
      element.props.val.reporteddate = moment(element.props.val.reporteddate).format(
        "MMM D, YYYY h:mm A"
      )
      items.push(element.props.val)
    })
  }

  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  let tempalltask = useSelector((state) => state.Tracking.allTaskTempData)

  /**************************for all projects*********************************/
  const { data: allProjectsRes } = useFetch(
    key.get_all_projects,
    `/trackingprojects/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )

  let allProjectsData = allProjectsRes?.data?.Items ?? []

  const dispatch = useDispatch()
  const { data: allTasksTypesRes } = useFetch(
    key.get_all_tasktypes,
    "/trackingtasktype",
    () => {},
    () => {},
    false,
    false
  )
  let allTaskTypes = allTasksTypesRes?.data ?? []

  const { data: allProjectMembers } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allProjectMembers?.data ?? []

  /***********************************************************/

  let filters = useSelector((state) => state.Tracking.filters)
  let isFiltered = useSelector((state) => state.Tracking.isFiltered)
  const [startDate, setStartDate] = useState(new Date())

  const allTrackingsList = useSelector((state) => state.Tracking.allTrackings)
  const authToken = useSelector((state) => state.Auth.authToken)
  let allWorkFlow = useSelector((state) => state.Tracking.allWorkFlow)
  let workflowId = []

  const { data: allBacklogsRes } = useFetch(
    key.get_backlogs_data,
    `/backloglist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    true
  )
  let allBacklogs = allBacklogsRes?.data ?? []

  const allArchiveTableData = useSelector((state) => state.Tracking.allArchiveTableData)
  var projectMembers = []

  const previousProject = usePrevious(selectedProject) || allProjectsData?.trackingprojectid
  const isProjectExist = (project) => {
    const result = allProjectsData.find(
      (e) => e.trackingprojectid == project || e.number == project
    )
    return result !== undefined ? true : false
  }
  const project = isProjectExist(selectedProject)
    ? selectedProject
    : previousProject || allProjectsData?.trackingprojectid
  allMembers.map((item) => {
    if (item.projectList?.includes(project)) {
      projectMembers.push({ label: item.personname, value: item.userId })
    }
  })

  if (selectedProject?.length > 0) {
    workflowId = allProjectsData.find((e) => e.trackingprojectid == selectedProject)
  } else {
    workflowId = allProjectsData
  }

  if (workflowId) workflowId = workflowId.workflow
  let Work_Flow = allWorkFlow[workflowId]

  let newArr = []

  Work_Flow &&
    Work_Flow[0]?.workFlow?.forEach((e, i) => {
      Object.keys(e)?.map((n) => {
        newArr.push(e[n])
      })
    })

  if (newArr.length > 0) {
    let result = []
    newArr.forEach((element, i) => {
      result.push({ label: element, value: i })
    })
  }

  if (allTaskTypes.length > 0) {
    let result = []
    allTaskTypes.forEach((item) => result.push({ label: item.label, value: item.id }))
    allTaskTypes = result
  }

  if (allProjectsData.length > 0) {
    let result = []
    allProjectsData.forEach((element) => {
      result.push({ label: element.label, value: element.trackingprojectid })
    })
    allProjectsData = result
  }

  if (allMembers.length > 0) {
    let result = []
    allMembers.forEach((element) => {
      result.push({ label: element.personname, value: element.userId })
    })
    allMembers = result
  }

  const clearAllFilter = () => {
    setTrackingFilters({
      projectsFilter: [],
      assignedtoFilter: [],
      typeFilter: [],
      statusFilter: [],
      priorityFilter: [],
      reportedByFilter: []
    })
    filters = {
      projects: [],
      status: [],
      type: [],
      reportedBy: [],
      assignedTo: [],
      priority: []
    }
    setFromDate(null)
    setToDate(null)
    dispatch(setFilters(filters))
    {
      tempalltask.length > 0
        ? dispatch(setAllTrackings(tempalltask))
        : dispatch(setAllTrackings(allTrackingsList))
    }
  }

  const downloadList = () => {
    var A = [
      [
        "Task No ",
        "Title",
        "Project",
        "Task Type",
        "Status",
        "Reported On",
        "Reported By",
        "Assigned To",
        "Priority"
      ]
    ]
    var csvRows = []

    items = [
      ...items.map((item) => {
        let priority = ""
        if (item.priority === "blocker") {
          priority = "Blocker1"
        } else {
          priority = item.priority
        }
        return {
          ...item,
          priority: priority
        }
      })
    ]

    items.forEach((element) => {
      element.title = element.title.replace(/,/g, "")
      A.push([
        element.trackingid,
        element.title,
        element.project_name,
        element.taskName,
        element?.status_name,
        moment(element.reporteddate, "MMMM DD, YYYY hh:mm A").format("(DD/MM/YYYY  h:mm:ss a)"),
        allMembers.find((e) => e.value === element.reportedby)?.label,
        element.assignedtoName,
        element.priority
      ])
    })

    for (var i = 0, l = A.length; i < l; ++i) {
      csvRows.push(A[i].join(","))
    }

    var csvString = csvRows.join("\n")
    var a = document.createElement("a")
    a.href = "data:attachment/csv," + encodeURIComponent(csvString)
    a.target = "_blank"
    a.download = `${trackingClientId}_task_list.csv`

    document.body.appendChild(a)
    a.click()
  }

  const onSelectOption = (type, value) => {
    filters[type] = value
    dispatch(setFilters(filters))
  }

  const formatDate = (date) => {
    return moment(date).format("YYYY-MM-DD")
  }

  const handleKeyDown = (e) => {
    if (isNaN(Number(e.key))) {
      e.preventDefault()
    }
  }

  const handleStartDateChange = (date) => {
    setFromDate(date)
  }

  const handleToDateChange = (date) => {
    setToDate(date)
  }

  let RefinedItems = []
  const refineTrackingId = allcheckTable?.map((item) => {
    RefinedItems.push(item.item?.trackingid)
  })

  const FetchArchiveMove = () => {
    if (allcheckTable?.length > 0) {
      let obj = {
        trackingid: RefinedItems
      }
      api
        .patch("/archivelist/" + trackingClientId, obj, {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            setShowAlertBox(true)
            let index = allTrackingsList.filter((element) =>
              RefinedItems.includes(element?.trackingid)
            )
            index.forEach((item) => {
              const itemIndex = allTrackingsList.findIndex(
                (element) => element.trackingid === item?.trackingid
              )
              if (itemIndex >= 0) {
                allTrackingsList.splice(itemIndex, 1)
              }
              allArchiveTableData.push(itemIndex)
            })
            dispatch(setAllTrackings([...allTrackingsList]))
            dispatch(setArchiveTableData([...allArchiveTableData]))
            setAlertMessage("Selected item has been moved to Archive successfully")
            setAllCheckTable([])
          }
        })
    }
  }
  const FetchBacklogsRetain = () => {
    if (allcheckTable?.length > 0) {
      let obj = {
        trackingid: RefinedItems
      }
      api
        .patch("backloglist/movetobacklog/" + trackingClientId, obj, {
          headers: {
            authorizationtoken: ` ${authToken}`
          }
        })
        .then(function (response) {
          if (response.status === 200) {
            setShowAlertBox(true)
            let index = allTrackingsList.filter((element) =>
              RefinedItems.includes(element?.trackingid)
            )
            index.forEach((item) => {
              const itemIndex = allTrackingsList.findIndex(
                (element) => element.trackingid === item?.trackingid
              )
              if (itemIndex >= 0) {
                allTrackingsList.splice(itemIndex, 1)
              }
              allBacklogs.push(itemIndex)
            })
            dispatch(setAllTrackings([...allTrackingsList]))
            client.setQueryData([key.get_backlogs_data], (oldData) => {
              return {
                ...oldData,
                data: allBacklogs
              }
            })
            setAlertMessage("Selected item has been moved to Backlogs successfully")
            setAllCheckTable([])
          }
        })
    }
  }

  return (
    <>
      <TrackingHeaderWrap>
        <Row>
          <Col xs={6} md={4} className='me-auto pe-0'>
            {" "}
            <Searchbox
              handleSearch={handleSearch}
              value={searchValue}
              placeholder={"Title/Task No."}
            />{" "}
          </Col>

          {/* Removed Button Area */}
          <Col className='text-end ps-0 filters_actions_section' xs={4}>
            {allcheckTable?.length > 0 && (
              <>
                <div className='move_button_section'>
                  <OverlayTrigger
                    placement='bottom'
                    overlay={<Tooltip id='archive-tooltip'>Archive</Tooltip>}
                  >
                    <button className='actions_move_button' onClick={FetchArchiveMove}>
                      <BiArchiveIn className='moved_icons' />
                    </button>
                  </OverlayTrigger>
                  {backlogsPermission?.view == true && backlogsPermission?.edit == true && (
                    <OverlayTrigger
                      placement='bottom'
                      overlay={<Tooltip id='backlogs-tooltip'>Backlogs</Tooltip>}
                    >
                      <button className='actions_move_button' onClick={FetchBacklogsRetain}>
                        <BacklogsIcon className='moved_icons' />
                      </button>
                    </OverlayTrigger>
                  )}
                </div>
              </>
            )}
            {showFilter ? (
              <div className='d-inline-block filter-dropdown'>
                <Dropdown id='filterDropDown'>
                  <Dropdown.Toggle
                    split={false}
                    className='me-1'
                    style={{
                      padding: "0px 0px",
                      backgroundColor: "transparent",
                      borderColor: "transparent"
                    }}
                  >
                    {isFiltered ? (
                      <img src={filtered} alt='filtered' />
                    ) : (
                      <img src={filter} alt='filter' />
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu className='p-0' onClick={(e) => e.stopPropagation()}>
                    <h5
                      className='d-flex mb-3 p-3 pt-3 pb-2'
                      style={{
                        justifyContent: "space-between",
                        borderBottom: "1px solid #f2f2f2"
                      }}
                    >
                      <div style={{ color: "black" }}>
                        <strong>Filters</strong>
                      </div>
                      {(trackingFilters.projectsFilter &&
                        trackingFilters.projectsFilter.length > 0) ||
                      (trackingFilters.assignedtoFilter &&
                        trackingFilters.assignedtoFilter.length > 0) ||
                      (trackingFilters.typeFilter && trackingFilters.typeFilter.length > 0) ||
                      (trackingFilters.statusFilter && trackingFilters.statusFilter.length > 0) ||
                      (trackingFilters.priorityFilter &&
                        trackingFilters.priorityFilter.length > 0) ||
                      (trackingFilters.reportedByFilter &&
                        trackingFilters.reportedByFilter.length > 0) ||
                      fromDate ||
                      toDate ? (
                        <Button
                          style={{ color: "#4EB6EE" }}
                          size='sm'
                          className='text-decoration-none p-0'
                          variant='link'
                          onClick={(e) => clearAllFilter()}
                        >
                          Clear All
                        </Button>
                      ) : (
                        <></>
                      )}
                    </h5>
                    <div
                      className='p-3 pt-0 pb-4'
                      style={{ minWidth: cardView === "Card" && "500px" }}
                    >
                      <Row>
                        <Col md={12} className='mt-0 mb-1 d-flex gap-3'>
                          <div className='w-50'>
                            <label className='form-label'>Project Name:</label>
                            <div className='d-flex'>
                              <MultiSelect
                                options={allProjectsData}
                                optionSelected={trackingFilters.projectsFilter}
                                setOptionSelected={(val) => {
                                  setTrackingFilters((prevState) => {
                                    return {
                                      ...prevState,
                                      projectsFilter: val
                                    }
                                  })
                                }}
                                onSelectOption={onSelectOption}
                                type={"Project"}
                              />
                            </div>
                          </div>
                          <div className='w-50'>
                            <label className='form-label'>Task Type:</label>
                            <div className='d-flex'>
                              <MultiSelect
                                options={allTaskTypes}
                                optionSelected={trackingFilters.typeFilter}
                                setOptionSelected={(val) =>
                                  setTrackingFilters((prevState) => {
                                    return { ...prevState, typeFilter: val }
                                  })
                                }
                                onSelectOption={onSelectOption}
                                type={"type"}
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md={12} className='mt-2 mb-1 d-flex gap-3'>
                          <div className='rage_filter_date'>
                            <label className='form-label'>From Date:</label>
                            <div className='d-flex'>
                              <DatePicker
                                className='reported_from_date px-4 w-100'
                                showIcon
                                icon={<CalendarMonthIcon className='calender-icon' />}
                                selected={fromDate}
                                onChange={handleStartDateChange}
                                dateFormat={"dd/MM/yyyy"}
                                placeholderText='From date'
                                onKeyDown={handleKeyDown}
                                maxDate={toDate && toDate}
                                isClearable
                              />
                            </div>
                          </div>
                          <div className='rage_filter_date'>
                            <label className='form-label'>To Date:</label>
                            <div className='d-flex'>
                              <DatePicker
                                className='reported_from_date px-4 w-100'
                                showIcon
                                icon={<CalendarMonthIcon className='calender-icon' />}
                                selected={toDate}
                                onChange={handleToDateChange}
                                dateFormat={"dd/MM/yyyy"}
                                placeholderText='To date'
                                onKeyDown={handleKeyDown}
                                minDate={fromDate && fromDate}
                                isClearable
                              />
                            </div>
                          </div>
                        </Col>

                        <Col md={12} className='mt-1 mb-1 d-flex gap-3'>
                          <div className='w-50'>
                            <label className='form-label'>Assigned To:</label>
                            <div className='d-flex'>
                              <MultiSelect
                                options={allMembers}
                                optionSelected={trackingFilters.assignedtoFilter}
                                setOptionSelected={(val) =>
                                  setTrackingFilters((prevState) => {
                                    return {
                                      ...prevState,
                                      assignedtoFilter: val
                                    }
                                  })
                                }
                                onSelectOption={onSelectOption}
                                type={"assignedTo"}
                              />
                            </div>
                          </div>
                          <div className='w-50'>
                            <label className='form-label'>Reported By:</label>
                            <div className='d-flex'>
                              <MultiSelect
                                options={allMembers}
                                optionSelected={trackingFilters.reportedByFilter}
                                setOptionSelected={(val) =>
                                  setTrackingFilters((prevState) => {
                                    return {
                                      ...prevState,
                                      reportedByFilter: val
                                    }
                                  })
                                }
                                onSelectOption={onSelectOption}
                                type={"reportedBy"}
                              />
                            </div>
                          </div>
                        </Col>
                      </Row>

                      {/* {(trackingFilters.projectsFilter &&
                        trackingFilters.projectsFilter.length > 0) ||
                        (trackingFilters.assignedtoFilter &&
                          trackingFilters.assignedtoFilter.length > 0) ||
                        (trackingFilters.typeFilter && trackingFilters.typeFilter.length > 0) ||
                        (trackingFilters.statusFilter && trackingFilters.statusFilter.length > 0) ||
                        (trackingFilters.priorityFilter &&
                          trackingFilters.priorityFilter.length > 0) ||
                        (trackingFilters.reportedByFilter &&
                          trackingFilters.reportedByFilter.length > 0) ? (
                      <Button
                        style={{
                          cursor: "pointer",
                          backgroundColor: "rgb(78, 182, 238)",
                          color: "rgb(255, 255, 255)",
                          borderColor: "rgb(78, 182, 238)",
                          marginLeft: "77%",
                          marginTop: "10px"
                        }}
                        className="btn btn-primary"
                        onClick={(e) => fetchTrackingFilter()}
                      >
                        Apply Filter
                      </Button>
                          ): (
                            <></>
                          )} */}
                      <Button
                        // style={{
                        //   cursor: "pointer",
                        //   backgroundColor: "rgb(78, 182, 238)",
                        //   color: "rgb(255, 255, 255)",
                        //   borderColor: "rgb(78, 182, 238)",
                        //   marginLeft: "78%",
                        //   marginTop: "10px"
                        // }}
                        className='filterbtn'
                        onClick={(e) => fetchTrackingFilter()}
                      >
                        Apply Filter
                        {filterloading && <Spinner size='sm' variant='light' animation='border' />}
                      </Button>
                    </div>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            ) : (
              <></>
            )}

            {items.length ? (
              <Button className='me-1 download_btn'>
                <img
                  alt='downloadIco'
                  src={download}
                  onClick={(e) => {
                    downloadList()
                  }}
                />
              </Button>
            ) : null}

            <Button
              className='me-1 btn-rad refresh_btn'
              onClick={(e) => {
                refreshAllTracking()
                setLoading(true)
              }}
            >
              {loading ? <Spinner size='sm' variant='light' animation='border' /> : <RefreshIcon />}
            </Button>

            {createPermission && (
              <Button className='add_task_btn'>
                <img
                  src={plus}
                  alt='add Task plus'
                  onClick={(e) => {
                    setTaskview("addnew")
                    setFormType("new")
                    setChangeInForm(false)
                  }}
                />
              </Button>
            )}
          </Col>
        </Row>
      </TrackingHeaderWrap>
    </>
  )
}
