import React, { useRef, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import Chart from "chart.js/auto"
import UIModal from "../../../../Reuseable/Modal"
import Tables from "../../../../Reuseable/Tables"
import moment from "moment"
import { useFetch } from "../../../../../Hooks/useFetch"
import { key } from "../../../../../data/queryKeys"

const DoughnutChart = ({ priorityCount, allProjectDashboard }) => {
  const trackingClientId = useSelector((state) => state.Auth.user.trackingclientid)
  const darkmode = useSelector((state) => state.Layout.darkmode)
  const priorityArray = Object.entries(priorityCount || {})?.map(([name, value]) => ({
    name,
    value
  }))
  const chartRef = useRef(null)
  const chartInstanceRef = useRef(null)
  const sum = priorityArray.reduce((accumulator, obj) => accumulator + obj.value, 0)
  const [showTaskCountsModal, setShowTaskCountsModal] = useState(false)
  const [filteredTasks, setFilteredTasks] = useState([])
  const [selectedPriority, setSelectedPriority] = useState(null)

  useEffect(() => {
    if (chartInstanceRef.current) {
      chartInstanceRef.current.destroy()
    }
    drawChart()
  }, [priorityCount, darkmode])

  let colorArray = []
  let colorAr = ["#B100FF", "#D06CFC", "#E1A7FA", "#F9EAFF", "#724187", "#5f407c"]

  function generateRandomColor() {
    if (!darkmode) {
      const getRandomComponent = () => Math.floor(Math.random() * 155) + 100 // Adjust range as needed

      // Generate RGB components
      const red = getRandomComponent()
      const green = getRandomComponent()
      const blue = getRandomComponent()

      // Convert components to hexadecimal and concatenate
      const color = `#${red.toString(16)}${green.toString(16)}${blue.toString(16)}`

      return color
    } else {
      const getRandomValue = () => Math.floor(Math.random() * 155) + 100 // Adjust the range based on your preference

      const red = getRandomValue()
      const green = getRandomValue()
      const blue = getRandomValue()

      return `rgb(${red}, ${green}, ${blue})`
    }
  }

  useEffect(() => {
    for (let i = 0; i < priorityArray.length; i++) {
      const randomColor = generateRandomColor()
      colorArray.push(randomColor)
    }
  }, [darkmode])

  for (let i = 0; i < priorityArray.length; i++) {
    const randomColor = generateRandomColor()
    colorArray.push(randomColor)
  }
  const drawChart = () => {
    const chartCanvas = chartRef.current.getContext("2d")

    chartInstanceRef.current = new Chart(chartCanvas, {
      type: "doughnut",
      data: {
        labels: priorityArray.map((item) => item.name),
        datasets: [
          {
            label: "",
            data: priorityArray.map((item) => item.value),
            borderColor: "transparent",
            backgroundColor: colorArray.filter(
              (color) => color !== "#FFFFFF" && color !== "#0a0a0a"
            )
          }
        ]
      },

      options: {
        onHover: (event, elements) => {
          if (elements && elements.length > 0) {
            const hoveredPriorityIndex = elements[0].index
            const hoveredPriority = priorityArray[hoveredPriorityIndex]?.name
            setSelectedPriority(hoveredPriority)
          } else {
            setSelectedPriority(null)
          }
        },
        cutout: "70%",
        spacing: 0,
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              boxWidth: 10,
              color: darkmode ? "white" : "black"
            }
          }
        }
      },
      plugins: [
        {
          beforeDraw: function (chart) {
            var width = chart.width,
              height = chart.height,
              ctx = chart.ctx
            ctx.restore()
            var fontSize = "3"
            ctx.font = fontSize + "em sans-serif"
            ctx.textBaseline = "middle"
            ctx.fillStyle = darkmode ? "white" : "black"
            var text = `${sum}`,
              textX = Math.round((width - ctx.measureText(text).width) / 2),
              textY = height / 2 - 20
            ctx.fillText(text, textX, textY)
            ctx.save()
          }
        }
      ]
    })
  }

  const taskColumns = (
    <>
      <th className='text-nowrap'>
        <span className='ps-1'>Task No</span>
      </th>
      <th className='text-nowrap'>Title</th>
      <th className='text-nowrap'>Timeline</th>
      <th className='text-nowrap'>Reported On</th>
      <th className='text-nowrap'>Reported By</th>
      <th className='text-nowrap'>Assigned To</th>
      <th className='text-nowrap'>Priority</th>
    </>
  )

  const { data: allMembersRes } = useFetch(
    key.get_all_members,
    `/trackingusers/memberlist/${trackingClientId}`,
    () => {},
    () => {},
    false,
    false
  )
  let allMembers = allMembersRes?.data ?? []

  const openTaskModal = (id) => {
    let filtered = () => {
      return allProjectDashboard?.prioritytasks
        .filter((item) => {
          if (item.priority === id) {
            return item
          }
        })
        .map((item, i) => {
          let reportedby = allMembers
            .filter((member) => member.userId === item.reportedby)
            .map((member) => member.personname)
            .join(", ")

          let assignedTo = allMembers
            .filter((member) => member.userId === item.assignedto)
            .map((member) => member.personname)
            .join(", ")

          if (assignedTo === "" || assignedTo === "-") {
            assignedTo = "Not Assigned"
          }

          return (
            <tr key={i} val={item}>
              <td className='p-0'>
                {item.trackingclientid}-{item.trackingid}
              </td>
              <td className='text-nowrap'>
                <span>{item?.title}</span>
              </td>
              <td className='text-capitalize text-nowrap'>
                <span>{item.timeline === "" ? "-" : item.timeline}</span>
              </td>
              <td className='text-capitalize text-nowrap'>
                <span>
                  {item.reporteddate === ""
                    ? "-"
                    : moment(item.reporteddate).format("MMM D, YYYY hh:mm A")}
                </span>
              </td>
              <td className='text-capitalize text-nowrap'>
                <span>{reportedby}</span>
              </td>
              <td className='text-capitalize text-nowrap'>
                <span>{assignedTo}</span>
              </td>
              <td className='text-nowrap'>
                <span className='text-capitalize'>
                  {item.priority === "blocker"
                    ? "Blocker Level 1"
                    : item.priority === "blocker2"
                    ? "Blocker Level 2"
                    : item.priority === ""
                    ? "-"
                    : item.priority}
                </span>
              </td>
            </tr>
          )
        })
    }
    setFilteredTasks(filtered)
    setShowTaskCountsModal(true)
  }

  return (
    <div className='project_dashboard_chart p-2'>
      <p className='mb-0 text-white'>Tasks by Priority</p>
      <div className='doughnut_chart_container p-1'>
        <canvas
          style={{ cursor: "pointer" }}
          onClick={() => {
            if (selectedPriority) {
              openTaskModal(selectedPriority)
            }
          }}
          ref={chartRef}
          className='myDashboard_doughnut_chart'
        />
      </div>
      <UIModal
        className='project_dashboard_modal'
        showModal={showTaskCountsModal}
        setShowModal={setShowTaskCountsModal}
        size={"xl"}
      >
        <Tables
          setProjectTable={true}
          columns={taskColumns}
          data={filteredTasks}
          showInfiniteScroll={false}
          notfound={"No Tasks Found"}
          // loading={loading}
        />
      </UIModal>
    </div>
  )
}

export default DoughnutChart
