import Styled from 'styled-components';

export const Selection = Styled.div`
  .CardFilter{
    margin-left: 1rem;
  }
  .selections{
    padding: 0.3rem 1rem;
    border-radius: 10px;
    background-color: red;
  }
`;

export const CardView = Styled.div`
/*CARD VIEW BUTTON STYLE */
    *{
      padding:0;
      margin:0;
      box-sizing:border-box;
    }
    
    h2.active{
      color:#4EB6EE;
    }

    h2,span{
      margin-right:16px;
      font-size: 15px;
    }
    span{
      color:${({ theme }) => theme["body-text"]};
    }

    .view{
      margin-left: 1rem;
      display: flex;
      align-items: center;
      text-align: center;
      gap: 10px;
      margin-bottom:1rem;
    }
    .Dash{
      font-size: 15px;
    }
    .Cardbtn{
      font-size: 15px;
      cursor: pointer;
    }
    .Listbtn{
      font-size: 15px;
      cursor: pointer;
    }
    .add_task_btn{
      color: #FFFFFF;
      font-size: 15px;
      padding: 5px 12px;
      background-color: #4EB6EE;
      border: none;
      outline: none;
      border-radius: 5px;
    }
`;

const TrackingModalView = Styled.div`
  .alert{
    height: 10px;
  }
  .table-view-card
  {
      background-color: ${({ theme }) => theme["tableViewCard"]};
  }
  .btn-close {
      margin-bottom: 20px;
      margin-right: 5px;
  }

  .dropdown-toggle::after
  {
      display:none;
  }

  .react-datepicker-wrapper{
    width:100%;
  }

  .modal-header {
      position: relative;
  }
  .tr-color{
    color: ${({ theme }) => theme["themeColor"]};
  }

  .gradientline{
    background: linear-gradient(to right, #4285f4 25%, #34a853 25%, #fabb05 50%, #e94235 75%);
    position: relative;
    content: '';
    height: 4px;
    right: 0;
    left: 0;
  }

  .modal-header:after {
    background: linear-gradient(to right, #4285f4 25%, #34a853 25%, #fabb05 50%, #e94235 75%);
    position: absolute;
    content: '';
    height: 4px;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .trackingPage .table-responsive {
    overflow: hidden;
    overflow-x: auto;
  }

  .trackingPage table thead th {
    font-size: 14px;
    vertical-align: middle;
    white-space: nowrap;
  }

  .userRoleTypePart  {
    color: ${({ theme }) => theme["body-text"]};
  }

  .closeicon{
    margin-top: 2px;
    color: #4EB6EE;
    float: left;
  }

  .block-icon{
    color: red;
  }

  .taskconatiner{
    background-color:${({ theme }) => theme["tableViewCard"]};;
  }

  .serchDropdownMenu
  {
    min-width: 250px;
    padding: 10px;
    top: 5px !important;
    box-shadow: 0px 2px 11px 0 rgb(0 0 0 / 24%);
    border: none;
  }
  .serchDropdownMenu input{
    font-size:13px;
  }

  .filter-dropdown label{
    font-weight: 600;
    font-size: 14px;
    line-height: normal;
    margin-bottom: 5px;
  }
  .filter-dropdown .dropdown-menu {
    inset: 0px 45px auto auto !important;
    transform: inherit !important;
    top: 45px !important;
    right: 0 !important;
    width: 1500% !important;
    position:relative;
  }
  .filter-dropdown .dropdown-menu:after {
    border: solid transparent;
    content: " ";
    border-left: 15px solid transparent;
    border-right: 0px solid transparent;
    border-bottom: 12px solid #ffffff;
    position: absolute;
    top: -6px;
    right: 15px;
    transform: rotate(222deg);
}
  .filter-dropdown .crosIcon
  {
    width: 15px;
    height: 15px;
  }
  .tracking-count{
    margin-right: 7px;
    margin-bottom : 5px;
    padding: 6px;
    border-radius: 5px;
    font-size: 13px;
    color : white;
    display: inline-block;
  }

  .tasks-tabs .nav-link.active {
    color:  #4EB6EE;
  }

  .tracking-id{
    cursor: pointer;
  }
  
  .tasks-tabs  .nav-link:hover {
    border-color:  #292a29;
  }

  .nav-tabs .nav-link{
    border: 0px;
  }
 
  .edit-content { 
    width: 300px;
    display: inline-flex;
    padding: 7px 20px 7px 10px;
    color: ${({ theme }) => theme["themeColor"]};
  }
  .responsive-iframe {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
  .select-options{
    padding: 7px 20px 7px 10px;
    width :200px;
  }
  .imgRow{
    margin-top: 10px;
    display: inline-flex;
    flex-direction: row;
  }
  .downloadbtn{
    color: ${({ theme }) => theme["themeColor"]}; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .file{
    display: inline-flex;
    flex-direction: row;
    
  }
  .Filecontainer{
    margin-bottom: 30px;
  }
  .filename{
    color: ${({ theme }) => theme["themeColor"]};
    font-size: 9px;
    height: 30px;
    margin-top: 5px;
    width: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  .File_spinner{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .videoname{
    width: 200px;
  }
  .refreshicon{
    float: right;
    color: #4EB6EE;
    bottom: 9px;
  }
  .nodata{
    color: ${({ theme }) => theme["themeColor"]};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .savechangesbtn{
    float: right;
  }
  .removebtn{
    right : 13px;
    bottom: 30px;
  }
  .deletebtn{
    bottom: 20px;
    color: #c60202;
    height: 20px;
    width: 1px;
  }
  .addbtn{
    color: ${({ theme }) => theme["themeColor"]};
  }
  .downloadicon{
    color: ${({ theme }) => theme["themeColor"]};
  }
  .imgRow{
    margin-top: 70px !IMPORTANT;
    display: inline-flex;
    flex-direction: row;
  }
  .edit-content:hover { 
    border-radius: 2px;
    background-color: ${({ theme }) => theme["tableViewCard"]};
  }
  .edit-cross{
    font-size:10px;
    width: 20px;
    height : 20px;
    border : 1px solid #c5bfbf;
    border-radius: 50%;
    background: #f1f1f1;
    color: #998e8e;
    cursor: pointer;
    padding-top : 3px;
    margin-top:3px;
  }
  .clone-box{
    text-align: center;
  }
  
  .clone{
    cursor:pointer;
    width: 150px;
   
    background-color: ${({ theme }) => theme["Clonebg"]};
    padding: 5px;
    border-radius: 50px;
    color: #3a3a3a;
  }
  .clone:hover{
    background-color: ${({ theme }) => theme["Cloneag"]};
    color : #3a3a3a;
  }

  .description-edit span{
    background-color : #00000000 !important;
  }
  .image-view-document{
    div{
      img{
        height: 120px;
        width: 120px;
      }
    }
  }
  .__react_modal_image__modal_container{
    top: 65px;
  }
  .__react_modal_image__modal_content{
    div{
      img{
        height: unset;
        width: unset;
      }
    }
  }
  @media (min-width: 1220px) {
    .taskconatiner{
      padding : 5px 110px  5px 110px ! important;
    }
    .title{
      padding : 0px 5px  5px 90px ! important;
    }
  }

  @media (max-width: 1220px) and (min-width: 992px) {
    .edit-content{
      width: 230px;
    }
  }

  @media (min-width: 576px) {
    .pagetitle{
      display:none;
    }
   }

  @media (min-width: 992px) {
    .trackingPage table tbody tr td .mobHead {
      display: none;
    }
    .fileinput {
      width: 700px !important;
    }
    .fileuploadcontainer{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 1350px) {
    .trackingPage table tbody tr td {
    }
  }

  @media (max-width: 1199px) {
    .trackingPage table tbody tr td {
    }
    .addTrack {
      padding-left: 0;
    }

    .addTrack .btn {
      padding: 8px 10px;
      font-size: 13px;
    }
  }

  @media (max-width: 500px) 
  {
    .trackingPage table tbody tr td {
      white-space: break-spaces;
      padding: 10px 15px !important;
      width: auto !important;
      display: block;
    }
    .trackingPage table tbody   {
      white-space: break-spaces;
      padding: 10px 15px !important;
      width: auto !important;
      display: block;
    }
  }

  @media (max-width: 991px) {
    .addTrack {
      padding-left: 15px;
      margin-top: 10px;
    }
    .titleedit{
      width: 30px;
      height: 30px;
    }
    .titleicon{
      margin-left: -10px;
    }
    .addTrack .add-new-text {
    }
    .trackingPage table tbody tr td {
      white-space: break-spaces;
      padding: 10px 15px !important;
      width: auto !important;
      display: block;
    }
    .trackingPage table thead th {
      display: none;
    }
    .trackingPage table tbody   {
      white-space: break-spaces;
      padding: 10px 15px !important;
      width: auto !important;
    }
    .trackingPage table tbody,
    .trackingPage table td,
    .trackingPage table tr {
      border-width: 0 !important;
    }
    .trackingPage table tr {}
    .trackingPage table.table-striped>tbody>tr:nth-of-type(odd)>* {
    }
    .trackingPage table tbody tr td.actionBtns {
      border-top: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
    }
  }

  @media (max-width:767px)
  {
    .filter-dropdown .dropdown-menu {
      width: 250px !important;
    }
    tbody{
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 612px) {
    .edit-content{
      width: 180px;
      word-break: break-all;
    }
    .select-options{
      width: 150px;
    }
  }
        
  @media (max-width:575px)
  {
    .filter-dropdown .dropdown-menu {
      right: -51px !important;
    }
    .filter-dropdown .dropdown-menu:after {
      right: 50%;
      margin-right: 15px;
    }
    .pagetitle{
      color: ${({ theme }) => theme["themeColor"]};
    }
    .Filecontainer{
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: 387px) {
    .select-options{
      width: 100px;
    }
    .edit-content{
      width: 180px;
      word-break: break-all;
    }
  }

  @media screen and (min-width: 320px) and (max-width: 480px){
    .addTask{
      button{
        font-size: 12px;
        padding: 4px 10px;
      }
    }
  }

  @media (min-width: 280px) and (max-width: 320px){
    .addTask{
      & button{
        font-size: 10px;
        padding: 4px 10px;
      }
    }
  }
`;

export default TrackingModalView;

